export const ACT = {
  ACT_P1: {
    ACT_P1_R1: 1,
    ACT_P1_R2: 2,
    ACT_P1_R3: 3,
    ACT_P1_R4: 4,
    ACT_P1_R5: 5,
  },
  ACT_P2: {
    ACT_P2_R1: 1,
    ACT_P2_R2: 2,
    ACT_P2_R3: 3,
    ACT_P3_R4: 4,
    ACT_P2_R5: 5,
  },
  ACT_P3: {
    ACT_P3_R1: 1,
    ACT_P3_R2: 2,
    ACT_P3_R3: 3,
    ACT_P3_R4: 4,
    ACT_P3_R5: 5,
  },
  ACT_P4: {
    ACT_P4_R1: 1,
    ACT_P4_R2: 2,
    ACT_P4_R3: 3,
    ACT_P4_R4: 4,
    ACT_P4_R5: 5,
  },
  ACT_P5: {
    ACT_P5_R1: 1,
    ACT_P5_R2: 2,
    ACT_P5_R3: 3,
    ACT_P5_R4: 4,
    ACT_P5_R5: 5,
  },
};

export const POEM = {
  POEM_P1: {
    POEM_P1_R1: 0,
    POEM_P1_R2: 1,
    POEM_P1_R3: 2,
    POEM_P1_R4: 3,
    POEM_P1_R5: 4,
  },
  POEM_P2: {
    POEM_P2_R1: 0,
    POEM_P2_R2: 1,
    POEM_P2_R3: 2,
    POEM_P2_R4: 3,
    POEM_P2_R5: 4,
  },
  POEM_P3: {
    POEM_P3_R1: 0,
    POEM_P3_R2: 1,
    POEM_P3_R3: 2,
    POEM_P3_R4: 3,
    POEM_P3_R5: 4,
  },
  POEM_P4: {
    POEM_P4_R1: 0,
    POEM_P4_R2: 1,
    POEM_P4_R3: 2,
    POEM_P4_R4: 3,
    POEM_P4_R5: 4,
  },
  POEM_P5: {
    POEM_P5_R1: 0,
    POEM_P5_R2: 1,
    POEM_P5_R3: 2,
    POEM_P5_R4: 3,
    POEM_P5_R5: 4,
  },
  POEM_P6: {
    POEM_P6_R1: 0,
    POEM_P6_R2: 1,
    POEM_P6_R3: 2,
    POEM_P6_R4: 3,
    POEM_P6_R5: 4,
  },
  POEM_P7: {
    POEM_P7_R1: 0,
    POEM_P7_R2: 1,
    POEM_P7_R3: 2,
    POEM_P7_R4: 3,
    POEM_P7_R5: 4,
  },
};

export const UCT = {
  UCT_P1: {
    UCT_P1_R1: 0,
    UCT_P1_R2: 1,
    UCT_P1_R3: 2,
    UCT_P1_R4: 3,
    UCT_P1_R5: 4,
  },
  UCT_P2: {
    UCT_P2_R1: 0,
    UCT_P2_R2: 1,
    UCT_P2_R3: 2,
    UCT_P2_R4: 3,
    UCT_P2_R5: 4,
  },
  UCT_P3: {
    UCT_P3_R1: 0,
    UCT_P3_R2: 1,
    UCT_P3_R3: 2,
    UCT_P3_R4: 3,
    UCT_P3_R5: 4,
  },
  UCT_P4: {
    UCT_P4_R1: 0,
    UCT_P4_R2: 1,
    UCT_P4_R3: 2,
    UCT_P4_R4: 3,
    UCT_P4_R5: 4,
  },
};

export const DLQI = {
  DLQI_P1: {
    DLQI_P1_R1: 3,
    DLQI_P1_R2: 2,
    DLQI_P1_R3: 1,
    DLQI_P1_R4: 0,
    DLQI_P1_R5: null,
  },
  DLQI_P2: {
    DLQI_P2_R1: 3,
    DLQI_P2_R2: 2,
    DLQI_P2_R3: 1,
    DLQI_P2_R4: 0,
    DLQI_P2_R5: null,
  },
  DLQI_P3: {
    DLQI_P3_R1: 3,
    DLQI_P3_R2: 2,
    DLQI_P3_R3: 1,
    DLQI_P3_R4: 0,
    DLQI_P3_R5: null,
  },
  DLQI_P4: {
    DLQI_P4_R1: 3,
    DLQI_P4_R2: 2,
    DLQI_P4_R3: 1,
    DLQI_P4_R4: 0,
    DLQI_P4_R5: null,
  },
  DLQI_P5: {
    DLQI_P5_R1: 3,
    DLQI_P5_R2: 2,
    DLQI_P5_R3: 1,
    DLQI_P5_R4: 0,
    DLQI_P5_R5: null,
  },
  DLQI_P6: {
    DLQI_P6_R1: 3,
    DLQI_P6_R2: 2,
    DLQI_P6_R3: 1,
    DLQI_P3_R4: 0,
    DLQI_P6_R5: null,
  },
  DLQI_P7: {
    DLQI_P7_R1: 1,
    DLQI_P7_R2: 0,
    DLQI_P7_R3: null,
  },
  DLQI_P7A: {
    DLQI_P7A_R1: 2,
    DLQI_P7A_R2: 1,
    DLQI_P7A_R3: 0,
  },
  DLQI_P8: {
    DLQI_P8_R1: 3,
    DLQI_P8_R2: 2,
    DLQI_P8_R3: 1,
    DLQI_P8_R4: 0,
    DLQI_P8_R5: null,
  },
  DLQI_P9: {
    DLQI_P9_R1: 3,
    DLQI_P9_R2: 2,
    DLQI_P9_R3: 1,
    DLQI_P9_R4: 0,
    DLQI_P9_R5: null,
  },
  DLQI_P10: {
    DLQI_P10_R1: 3,
    DLQI_P10_R2: 2,
    DLQI_P10_R3: 1,
    DLQI_P10_R4: 0,
    DLQI_P10_R5: null,
  },
};

export const PGA = {
  PGA_P1: {
    PGA_P1_R1: 0,
    PGA_P1_R2: 1,
    PGA_P1_R3: 2,
    PGA_P1_R4: 3,
    PGA_P1_R5: 4,
    PGA_P1_R6: 5,
  },
  PGA_P2: {
    PGA_P2_R1: 0,
    PGA_P2_R2: 1,
    PGA_P2_R3: 2,
    PGA_P2_R4: 3,
    PGA_P2_R5: 4,
    PGA_P2_R6: 5,
  },
  PGA_P3: {
    PGA_P3_R1: 0,
    PGA_P3_R2: 1,
    PGA_P3_R3: 2,
    PGA_P3_R4: 3,
    PGA_P3_R5: 4,
    PGA_P3_R6: 5,
  },
};

export const CAT = {
  CAT_P1: {
    CAT_P1_R1: 0,
    CAT_P1_R2: 1,
    CAT_P1_R3: 2,
    CAT_P1_R4: 3,
    CAT_P1_R5: 4,
    CAT_P1_R6: 5,
  },
  CAT_P2: {
    CAT_P2_R1: 0,
    CAT_P2_R2: 1,
    CAT_P2_R3: 2,
    CAT_P3_R4: 3,
    CAT_P2_R5: 4,
    CAT_P2_R6: 5,
  },
  CAT_P3: {
    CAT_P3_R1: 0,
    CAT_P3_R2: 1,
    CAT_P3_R3: 2,
    CAT_P3_R4: 3,
    CAT_P3_R5: 4,
    CAT_P3_R6: 5,
  },
  CAT_P4: {
    CAT_P4_R1: 0,
    CAT_P4_R2: 1,
    CAT_P4_R3: 2,
    CAT_P4_R4: 3,
    CAT_P4_R5: 4,
    CAT_P4_R6: 5,
  },
  CAT_P5: {
    CAT_P5_R1: 0,
    CAT_P5_R2: 1,
    CAT_P5_R3: 2,
    CAT_P5_R4: 3,
    CAT_P5_R5: 4,
    CAT_P5_R6: 5,
  },
  CAT_P6: {
    CAT_P6_R1: 0,
    CAT_P6_R2: 1,
    CAT_P6_R3: 2,
    CAT_P6_R4: 3,
    CAT_P6_R5: 4,
    CAT_P6_R6: 5,
  },
  CAT_P7: {
    CAT_P7_R1: 0,
    CAT_P7_R2: 1,
    CAT_P7_R3: 2,
    CAT_P7_R4: 3,
    CAT_P7_R5: 4,
    CAT_P7_R6: 5,
  },
  CAT_P8: {
    CAT_P8_R1: 0,
    CAT_P8_R2: 1,
    CAT_P8_R3: 2,
    CAT_P8_R4: 3,
    CAT_P8_R5: 4,
    CAT_P8_R6: 5,
  },
};

export const WHOQOL_BREF = {
  WHOQOL_BREF_P1: {
    WHOQOL_BREF_P1_R1: 1,
    WHOQOL_BREF_P1_R2: 2,
    WHOQOL_BREF_P1_R3: 3,
    WHOQOL_BREF_P1_R4: 4,
    WHOQOL_BREF_P1_R5: 5,
  },
  WHOQOL_BREF_P2: {
    WHOQOL_BREF_P2_R1: 1,
    WHOQOL_BREF_P2_R2: 2,
    WHOQOL_BREF_P2_R3: 3,
    WHOQOL_BREF_P2_R4: 4,
    WHOQOL_BREF_P2_R5: 5,
  },
  WHOQOL_BREF_P3: {
    WHOQOL_BREF_P3_R1: 5,
    WHOQOL_BREF_P3_R2: 4,
    WHOQOL_BREF_P3_R3: 3,
    WHOQOL_BREF_P3_R4: 2,
    WHOQOL_BREF_P3_R5: 1,
  },
  WHOQOL_BREF_P4: {
    WHOQOL_BREF_P4_R1: 5,
    WHOQOL_BREF_P4_R2: 4,
    WHOQOL_BREF_P4_R3: 3,
    WHOQOL_BREF_P4_R4: 2,
    WHOQOL_BREF_P4_R5: 1,
  },
  WHOQOL_BREF_P5: {
    WHOQOL_BREF_P5_R1: 1,
    WHOQOL_BREF_P5_R2: 2,
    WHOQOL_BREF_P5_R3: 3,
    WHOQOL_BREF_P5_R4: 4,
    WHOQOL_BREF_P5_R5: 5,
  },
  WHOQOL_BREF_P6: {
    WHOQOL_BREF_P6_R1: 1,
    WHOQOL_BREF_P6_R2: 2,
    WHOQOL_BREF_P6_R3: 3,
    WHOQOL_BREF_P6_R4: 4,
    WHOQOL_BREF_P6_R5: 5,
  },
  WHOQOL_BREF_P7: {
    WHOQOL_BREF_P7_R1: 1,
    WHOQOL_BREF_P7_R2: 2,
    WHOQOL_BREF_P7_R3: 3,
    WHOQOL_BREF_P7_R4: 4,
    WHOQOL_BREF_P7_R5: 5,
  },
  WHOQOL_BREF_P8: {
    WHOQOL_BREF_P8_R1: 1,
    WHOQOL_BREF_P8_R2: 2,
    WHOQOL_BREF_P8_R3: 3,
    WHOQOL_BREF_P8_R4: 4,
    WHOQOL_BREF_P8_R5: 5,
  },
  WHOQOL_BREF_P9: {
    WHOQOL_BREF_P9_R1: 1,
    WHOQOL_BREF_P9_R2: 2,
    WHOQOL_BREF_P9_R3: 3,
    WHOQOL_BREF_P9_R4: 4,
    WHOQOL_BREF_P9_R5: 5,
  },
  WHOQOL_BREF_P10: {
    WHOQOL_BREF_P10_R1: 1,
    WHOQOL_BREF_P10_R2: 2,
    WHOQOL_BREF_P10_R3: 3,
    WHOQOL_BREF_P10_R4: 4,
    WHOQOL_BREF_P10_R5: 5,
  },
  WHOQOL_BREF_P11: {
    WHOQOL_BREF_P11_R1: 1,
    WHOQOL_BREF_P11_R2: 2,
    WHOQOL_BREF_P11_R3: 3,
    WHOQOL_BREF_P11_R4: 4,
    WHOQOL_BREF_P11_R5: 5,
  },
  WHOQOL_BREF_P12: {
    WHOQOL_BREF_P12_R1: 1,
    WHOQOL_BREF_P12_R2: 2,
    WHOQOL_BREF_P12_R3: 3,
    WHOQOL_BREF_P12_R4: 4,
    WHOQOL_BREF_P12_R5: 5,
  },
  WHOQOL_BREF_P13: {
    WHOQOL_BREF_P13_R1: 1,
    WHOQOL_BREF_P13_R2: 2,
    WHOQOL_BREF_P13_R3: 3,
    WHOQOL_BREF_P13_R4: 4,
    WHOQOL_BREF_P13_R5: 5,
  },
  WHOQOL_BREF_P14: {
    WHOQOL_BREF_P14_R1: 1,
    WHOQOL_BREF_P14_R2: 2,
    WHOQOL_BREF_P14_R3: 3,
    WHOQOL_BREF_P14_R4: 4,
    WHOQOL_BREF_P14_R5: 5,
  },
  WHOQOL_BREF_P15: {
    WHOQOL_BREF_P15_R1: 1,
    WHOQOL_BREF_P15_R2: 2,
    WHOQOL_BREF_P15_R3: 3,
    WHOQOL_BREF_P15_R4: 4,
    WHOQOL_BREF_P15_R5: 5,
  },
  WHOQOL_BREF_P16: {
    WHOQOL_BREF_P16_R1: 1,
    WHOQOL_BREF_P16_R2: 2,
    WHOQOL_BREF_P16_R3: 3,
    WHOQOL_BREF_P16_R4: 4,
    WHOQOL_BREF_P16_R5: 5,
  },
  WHOQOL_BREF_P17: {
    WHOQOL_BREF_P17_R1: 1,
    WHOQOL_BREF_P17_R2: 2,
    WHOQOL_BREF_P17_R3: 3,
    WHOQOL_BREF_P17_R4: 4,
    WHOQOL_BREF_P17_R5: 5,
  },
  WHOQOL_BREF_P18: {
    WHOQOL_BREF_P18_R1: 1,
    WHOQOL_BREF_P18_R2: 2,
    WHOQOL_BREF_P18_R3: 3,
    WHOQOL_BREF_P18_R4: 4,
    WHOQOL_BREF_P18_R5: 5,
  },
  WHOQOL_BREF_P19: {
    WHOQOL_BREF_P19_R1: 1,
    WHOQOL_BREF_P19_R2: 2,
    WHOQOL_BREF_P19_R3: 3,
    WHOQOL_BREF_P19_R4: 4,
    WHOQOL_BREF_P19_R5: 5,
  },
  WHOQOL_BREF_P20: {
    WHOQOL_BREF_P20_R1: 1,
    WHOQOL_BREF_P20_R2: 2,
    WHOQOL_BREF_P20_R3: 3,
    WHOQOL_BREF_P20_R4: 4,
    WHOQOL_BREF_P20_R5: 5,
  },
  WHOQOL_BREF_P21: {
    WHOQOL_BREF_P21_R1: 1,
    WHOQOL_BREF_P21_R2: 2,
    WHOQOL_BREF_P21_R3: 3,
    WHOQOL_BREF_P21_R4: 4,
    WHOQOL_BREF_P21_R5: 5,
  },
  WHOQOL_BREF_P22: {
    WHOQOL_BREF_P22_R1: 1,
    WHOQOL_BREF_P22_R2: 2,
    WHOQOL_BREF_P22_R3: 3,
    WHOQOL_BREF_P22_R4: 4,
    WHOQOL_BREF_P22_R5: 5,
  },
  WHOQOL_BREF_P23: {
    WHOQOL_BREF_P23_R1: 1,
    WHOQOL_BREF_P23_R2: 2,
    WHOQOL_BREF_P23_R3: 3,
    WHOQOL_BREF_P23_R4: 4,
    WHOQOL_BREF_P23_R5: 5,
  },
  WHOQOL_BREF_P24: {
    WHOQOL_BREF_P24_R1: 1,
    WHOQOL_BREF_P24_R2: 2,
    WHOQOL_BREF_P24_R3: 3,
    WHOQOL_BREF_P24_R4: 4,
    WHOQOL_BREF_P24_R5: 5,
  },
  WHOQOL_BREF_P25: {
    WHOQOL_BREF_P25_R1: 1,
    WHOQOL_BREF_P25_R2: 2,
    WHOQOL_BREF_P25_R3: 3,
    WHOQOL_BREF_P25_R4: 4,
    WHOQOL_BREF_P25_R5: 5,
  },
  WHOQOL_BREF_P26: {
    WHOQOL_BREF_P26_R1: 5,
    WHOQOL_BREF_P26_R2: 4,
    WHOQOL_BREF_P26_R3: 3,
    WHOQOL_BREF_P26_R4: 2,
    WHOQOL_BREF_P26_R5: 1,
  },
};

export const HUR = {
  HUR_P1: {
    HUR_P1_R1: false,
    HUR_P1_R2: true,
    HUR_P1_R3: "No aplica",
  },
  HUR_P2: {
    HUR_P2_R1: false,
    HUR_P2_R2: true,
    HUR_P2_R3: "No aplica",
  },
  HUR_P3: {
    HUR_P3_R1: false,
    HUR_P3_R2: true,
    HUR_P3_R3: "No aplica",
  },
  HUR_P4: {
    HUR_P4_R1: false,
    HUR_P4_R2: true,
    HUR_P4_R3: "No aplica",
  },
  HUR_P5: {
    HUR_P5_R1: false,
    HUR_P5_R2: true,
    HUR_P5_R3: "No aplica",
  },
};

export const RIE_ASO = {
  RIE_ASO_P1: {
    RIE_ASO_P1_R1: { type: "Hight", value: true },
    RIE_ASO_P1_R2: { type: "Hight", value: false },
  },
  RIE_ASO_P2: {
    RIE_ASO_P2_R1: { type: "Hight", value: true },
    RIE_ASO_P2_R2: { type: "Hight", value: false },
  },
  RIE_ASO_P3: {
    RIE_ASO_P3_R1: { type: "Hight", value: true },
    RIE_ASO_P3_R2: { type: "Hight", value: false },
  },
  RIE_ASO_P4: {
    RIE_ASO_P4_R1: { type: "Hight", value: true },
    RIE_ASO_P4_R2: { type: "Hight", value: false },
  },
  RIE_ASO_P5: {
    RIE_ASO_P5_R1: { type: "Hight", value: true },
    RIE_ASO_P5_R2: { type: "Hight", value: false },
  },
  RIE_ASO_P6: {
    RIE_ASO_P6_R1: { type: "Hight", value: true },
    RIE_ASO_P6_R2: { type: "Hight", value: false },
  },
  RIE_ASO_P7: {
    RIE_ASO_P7_R1: { type: "Hight", value: true },
    RIE_ASO_P7_R2: { type: "Hight", value: false },
  },
  RIE_ASO_P8: {
    RIE_ASO_P8_R1: { type: "Hight", value: true },
    RIE_ASO_P8_R2: { type: "Hight", value: false },
  },
  RIE_ASO_P9: {
    RIE_ASO_P9_R1: { type: "Hight", value: true },
    RIE_ASO_P9_R2: { type: "Hight", value: false },
  },
  RIE_ASO_P10: {
    RIE_ASO_P10_R1: { type: "Hight", value: true },
    RIE_ASO_P10_R2: { type: "Hight", value: false },
  },
  RIE_ASO_P11: {
    RIE_ASO_P11_R1: { type: "Hight", value: true },
    RIE_ASO_P11_R2: { type: "Hight", value: false },
  },
  RIE_ASO_P12: {
    RIE_ASO_P12_R1: { type: "Low", value: true },
    RIE_ASO_P12_R2: { type: "Low", value: false },
  },
  RIE_ASO_P13: {
    RIE_ASO_P13_R1: { type: "Low", value: true },
    RIE_ASO_P13_R2: { type: "Low", value: false },
  },
  RIE_ASO_P14: {
    RIE_ASO_P14_R1: { type: "Low", value: true },
    RIE_ASO_P14_R2: { type: "Low", value: false },
  },
  RIE_ASO_P15: {
    RIE_ASO_P15_R1: { type: "Low", value: true },
    RIE_ASO_P15_R2: { type: "Low", value: false },
  },
  RIE_ASO_P16: {
    RIE_ASO_P16_R1: { type: "Low", value: true },
    RIE_ASO_P16_R2: { type: "Low", value: false },
  },
};

export const TOPAS = {
  TOPAS_P1: {
    TOPAS_P1_R1: 1,
    TOPAS_P1_R2: 0,
  },
  TOPAS_P1_B: {
    TOPAS_P1_B_R1: 1,
    TOPAS_P1_B_R2: 0,
  },
  TOPAS_P2_A: {
    TOPAS_P2_A_R1: 1,
    TOPAS_P2_A_R2: 0,
  },
  TOPAS_P2_B: {
    TOPAS_P2_B_R1: 1,
    TOPAS_P2_B_R2: 0,
  },
  TOPAS_P2_B_B: {
    TOPAS_P2_B_B_R1: 1,
    TOPAS_P2_B_B_R2: 0,
  },
  TOPAS_P3: {
    TOPAS_P3_R1: 1,
    TOPAS_P3_R2: 0,
  },
  TOPAS_P4: {
    TOPAS_P4_R1: 1,
    TOPAS_P4_R2: 0,
  },
  TOPAS_P5: {
    TOPAS_P5_R1: 1,
    TOPAS_P5_R2: 0,
  },
  TOPAS_P5_B: {
    TOPAS_P5_B_R1: 1,
    TOPAS_P5_B_R2: 0,
  },
  TOPAS_P6: {
    TOPAS_P6_R1: 1,
    TOPAS_P6_R2: 0,
  },
  TOPAS_P7: {
    TOPAS_P7_R1: 1,
    TOPAS_P7_R2: 0,
  },
  TOPAS_P7_A: {
    TOPAS_P7_A_R1: 1,
    TOPAS_P7_A_R2: 0,
  },
  TOPAS_P7_B: {
    TOPAS_P7_B_R1: 1,
    TOPAS_P7_B_R2: 0,
  },
  TOPAS_P8: {
    TOPAS_P8_R1: 1,
    TOPAS_P8_R2: 0,
  },
  TOPAS_P8_A: {
    TOPAS_P8_A_R1: 1,
    TOPAS_P8_A_R2: 0,
  },
  TOPAS_P8_B: {
    TOPAS_P8_B_R1: 1,
    TOPAS_P8_B_R2: 0,
  },
  TOPAS_P9: {
    TOPAS_P9_R1: 1,
    TOPAS_P9_R2: 0,
  },
  TOPAS_P9_B: {
    TOPAS_P9_B_R1: 1,
    TOPAS_P9_B_R2: 0,
  },
  TOPAS_P10: {
    TOPAS_P10_R1: 1,
    TOPAS_P10_R2: 0,
  },
  TOPAS_P11: {
    TOPAS_P11_R1: 1,
    TOPAS_P11_R2: 0,
  },
  TOPAS_P12: {
    TOPAS_P12_R1: 1,
    TOPAS_P12_R2: 0,
  },
  TOPAS_P13: {
    TOPAS_P13_R1: 1,
    TOPAS_P13_R2: 0,
  },
  TOPAS_P14: {
    TOPAS_P14_R1: 1,
    TOPAS_P14_R2: 0,
  },
  TOPAS_P15: {
    TOPAS_P15_R1: 1,
    TOPAS_P15_R2: 0,
  },
  TOPAS_P16: {
    TOPAS_P16_R1: 1,
    TOPAS_P16_R2: 0,
  },
};

export const EASI = {
  HEAD: {
    EASI_HEAD_AREA: {
      EASI_HEAD_AREA_R1: 0,
      EASI_HEAD_AREA_R2: 1,
      EASI_HEAD_AREA_R3: 2,
      EASI_HEAD_AREA_R4: 3,
      EASI_HEAD_AREA_R5: 4,
      EASI_HEAD_AREA_R6: 5,
      EASI_HEAD_AREA_R7: 6,
    },
    EASI_HEAD_ERITEMA: {
      EASI_HEAD_ERITEMA_R1: 0,
      EASI_HEAD_ERITEMA_R2: 1,
      EASI_HEAD_ERITEMA_R3: 2,
      EASI_HEAD_ERITEMA_R4: 3,
    },
    EASI_HEAD_EDEMA: {
      EASI_HEAD_EDEMA_R1: 0,
      EASI_HEAD_EDEMA_R2: 1,
      EASI_HEAD_EDEMA_R3: 2,
      EASI_HEAD_EDEMA_R4: 3,
    },
    EASI_HEAD_EXCORIACION: {
      EASI_HEAD_EXCORIACION_R1: 0,
      EASI_HEAD_EXCORIACION_R2: 1,
      EASI_HEAD_EXCORIACION_R3: 2,
      EASI_HEAD_EXCORIACION_R4: 3,
    },
    EASI_HEAD_LIQUENIFICACION: {
      EASI_HEAD_LIQUENIFICACION_R1: 0,
      EASI_HEAD_LIQUENIFICACION_R2: 1,
      EASI_HEAD_LIQUENIFICACION_R3: 2,
      EASI_HEAD_LIQUENIFICACION_R4: 3,
    },
  },
  TRUK: {
    EASI_TRUK_AREA: {
      EASI_TRUK_AREA_R1: 0,
      EASI_TRUK_AREA_R2: 1,
      EASI_TRUK_AREA_R3: 2,
      EASI_TRUK_AREA_R4: 3,
      EASI_TRUK_AREA_R5: 4,
      EASI_TRUK_AREA_R6: 5,
      EASI_TRUK_AREA_R7: 6,
    },
    EASI_TRUK_ERITEMA: {
      EASI_TRUK_ERITEMA_R1: 0,
      EASI_TRUK_ERITEMA_R2: 1,
      EASI_TRUK_ERITEMA_R3: 2,
      EASI_TRUK_ERITEMA_R4: 3,
    },
    EASI_TRUK_EDEMA: {
      EASI_TRUK_EDEMA_R1: 0,
      EASI_TRUK_EDEMA_R2: 1,
      EASI_TRUK_EDEMA_R3: 2,
      EASI_TRUK_EDEMA_R4: 3,
    },
    EASI_TRUK_EXCORIACION: {
      EASI_TRUK_EXCORIACION_R1: 0,
      EASI_TRUK_EXCORIACION_R2: 1,
      EASI_TRUK_EXCORIACION_R3: 2,
      EASI_TRUK_EXCORIACION_R4: 3,
    },
    EASI_TRUK_LIQUENIFICACION: {
      EASI_TRUK_LIQUENIFICACION_R1: 0,
      EASI_TRUK_LIQUENIFICACION_R2: 1,
      EASI_TRUK_LIQUENIFICACION_R3: 2,
      EASI_TRUK_LIQUENIFICACION_R4: 3,
    },
  },
  SUPERIOR_LIMBS: {
    EASI_SUPERIOR_LIMBS_AREA: {
      EASI_SUPERIOR_LIMBS_AREA_R1: 0,
      EASI_SUPERIOR_LIMBS_AREA_R2: 1,
      EASI_SUPERIOR_LIMBS_AREA_R3: 2,
      EASI_SUPERIOR_LIMBS_AREA_R4: 3,
      EASI_SUPERIOR_LIMBS_AREA_R5: 4,
      EASI_SUPERIOR_LIMBS_AREA_R6: 5,
      EASI_SUPERIOR_LIMBS_AREA_R7: 6,
    },
    EASI_SUPERIOR_LIMBS_ERITEMA: {
      EASI_SUPERIOR_LIMBS_ERITEMA_R1: 0,
      EASI_SUPERIOR_LIMBS_ERITEMA_R2: 1,
      EASI_SUPERIOR_LIMBS_ERITEMA_R3: 2,
      EASI_SUPERIOR_LIMBS_ERITEMA_R4: 3,
    },
    EASI_SUPERIOR_LIMBS_EDEMA: {
      EASI_SUPERIOR_LIMBS_EDEMA_R1: 0,
      EASI_SUPERIOR_LIMBS_EDEMA_R2: 1,
      EASI_SUPERIOR_LIMBS_EDEMA_R3: 2,
      EASI_SUPERIOR_LIMBS_EDEMA_R4: 3,
    },
    EASI_SUPERIOR_LIMBS_EXCORIACION: {
      EASI_SUPERIOR_LIMBS_EXCORIACION_R1: 0,
      EASI_SUPERIOR_LIMBS_EXCORIACION_R2: 1,
      EASI_SUPERIOR_LIMBS_EXCORIACION_R3: 2,
      EASI_SUPERIOR_LIMBS_EXCORIACION_R4: 3,
    },
    EASI_SUPERIOR_LIMBS_LIQUENIFICACION: {
      EASI_SUPERIOR_LIMBS_LIQUENIFICACION_R1: 0,
      EASI_SUPERIOR_LIMBS_LIQUENIFICACION_R2: 1,
      EASI_SUPERIOR_LIMBS_LIQUENIFICACION_R3: 2,
      EASI_SUPERIOR_LIMBS_LIQUENIFICACION_R4: 3,
    },
  },
  LOWER_LIMBS: {
    EASI_LOWER_LIMBS_AREA: {
      EASI_LOWER_LIMBS_AREA_R1: 0,
      EASI_LOWER_LIMBS_AREA_R2: 1,
      EASI_LOWER_LIMBS_AREA_R3: 2,
      EASI_LOWER_LIMBS_AREA_R4: 3,
      EASI_LOWER_LIMBS_AREA_R5: 4,
      EASI_LOWER_LIMBS_AREA_R6: 5,
      EASI_LOWER_LIMBS_AREA_R7: 6,
    },
    EASI_LOWER_LIMBS_ERITEMA: {
      EASI_LOWER_LIMBS_ERITEMA_R1: 0,
      EASI_LOWER_LIMBS_ERITEMA_R2: 1,
      EASI_LOWER_LIMBS_ERITEMA_R3: 2,
      EASI_LOWER_LIMBS_ERITEMA_R4: 3,
    },
    EASI_LOWER_LIMBS_EDEMA: {
      EASI_LOWER_LIMBS_EDEMA_R1: 0,
      EASI_LOWER_LIMBS_EDEMA_R2: 1,
      EASI_LOWER_LIMBS_EDEMA_R3: 2,
      EASI_LOWER_LIMBS_EDEMA_R4: 3,
    },
    EASI_LOWER_LIMBS_EXCORIACION: {
      EASI_LOWER_LIMBS_EXCORIACION_R1: 0,
      EASI_LOWER_LIMBS_EXCORIACION_R2: 1,
      EASI_LOWER_LIMBS_EXCORIACION_R3: 2,
      EASI_LOWER_LIMBS_EXCORIACION_R4: 3,
    },
    EASI_LOWER_LIMBS_LIQUENIFICACION: {
      EASI_LOWER_LIMBS_LIQUENIFICACION_R1: 0,
      EASI_LOWER_LIMBS_LIQUENIFICACION_R2: 1,
      EASI_LOWER_LIMBS_LIQUENIFICACION_R3: 2,
      EASI_LOWER_LIMBS_LIQUENIFICACION_R4: 3,
    },
  },
};

export const PASI = {
  HEAD: {
    PASI_HEAD_AREA: {
      PASI_HEAD_AREA_R1: 0,
      PASI_HEAD_AREA_R2: 1,
      PASI_HEAD_AREA_R3: 2,
      PASI_HEAD_AREA_R4: 3,
      PASI_HEAD_AREA_R5: 4,
      PASI_HEAD_AREA_R6: 5,
      PASI_HEAD_AREA_R7: 6,
    },
    PASI_HEAD_ERITEMA: {
      PASI_HEAD_ERITEMA_R1: 0,
      PASI_HEAD_ERITEMA_R2: 1,
      PASI_HEAD_ERITEMA_R3: 2,
      PASI_HEAD_ERITEMA_R4: 3,
      PASI_HEAD_ERITEMA_R5: 4,
    },
    PASI_HEAD_PICOR: {
      PASI_HEAD_PICOR_R1: 0,
      PASI_HEAD_PICOR_R2: 1,
      PASI_HEAD_PICOR_R3: 2,
      PASI_HEAD_PICOR_R4: 3,
      PASI_HEAD_PICOR_R5: 4,
    },
    PASI_HEAD_DESCAMACION: {
      PASI_HEAD_DESCAMACION_R1: 0,
      PASI_HEAD_DESCAMACION_R2: 1,
      PASI_HEAD_DESCAMACION_R3: 2,
      PASI_HEAD_DESCAMACION_R4: 3,
      PASI_HEAD_DESCAMACION_R5: 4,
    },
    PASI_HEAD_INDURACION: {
      PASI_HEAD_INDURACION_R1: 0,
      PASI_HEAD_INDURACION_R2: 1,
      PASI_HEAD_INDURACION_R3: 2,
      PASI_HEAD_INDURACION_R4: 3,
      PASI_HEAD_INDURACION_R5: 4,
    },
  },
  TRUK: {
    PASI_TRUK_AREA: {
      PASI_TRUK_AREA_R1: 0,
      PASI_TRUK_AREA_R2: 1,
      PASI_TRUK_AREA_R3: 2,
      PASI_TRUK_AREA_R4: 3,
      PASI_TRUK_AREA_R5: 4,
      PASI_TRUK_AREA_R6: 5,
      PASI_TRUK_AREA_R7: 6,
    },
    PASI_TRUK_ERITEMA: {
      PASI_TRUK_ERITEMA_R1: 0,
      PASI_TRUK_ERITEMA_R2: 1,
      PASI_TRUK_ERITEMA_R3: 2,
      PASI_TRUK_ERITEMA_R4: 3,
      PASI_TRUK_ERITEMA_R5: 4,
    },
    PASI_TRUK_PICOR: {
      PASI_TRUK_PICOR_R1: 0,
      PASI_TRUK_PICOR_R2: 1,
      PASI_TRUK_PICOR_R3: 2,
      PASI_TRUK_PICOR_R4: 3,
      PASI_TRUK_PICOR_R5: 4,
    },
    PASI_TRUK_DESCAMACION: {
      PASI_TRUK_DESCAMACION_R1: 0,
      PASI_TRUK_DESCAMACION_R2: 1,
      PASI_TRUK_DESCAMACION_R3: 2,
      PASI_TRUK_DESCAMACION_R4: 3,
      PASI_TRUK_DESCAMACION_R5: 4,
    },
    PASI_TRUK_INDURACION: {
      PASI_TRUK_INDURACION_R1: 0,
      PASI_TRUK_INDURACION_R2: 1,
      PASI_TRUK_INDURACION_R3: 2,
      PASI_TRUK_INDURACION_R4: 3,
      PASI_TRUK_INDURACION_R5: 4,
    },
  },
  SUPERIOR_LIMBS: {
    PASI_SUPERIOR_LIMBS_AREA: {
      PASI_SUPERIOR_LIMBS_AREA_R1: 0,
      PASI_SUPERIOR_LIMBS_AREA_R2: 1,
      PASI_SUPERIOR_LIMBS_AREA_R3: 2,
      PASI_SUPERIOR_LIMBS_AREA_R4: 3,
      PASI_SUPERIOR_LIMBS_AREA_R5: 4,
      PASI_SUPERIOR_LIMBS_AREA_R6: 5,
      PASI_SUPERIOR_LIMBS_AREA_R7: 6,
    },
    PASI_SUPERIOR_LIMBS_ERITEMA: {
      PASI_SUPERIOR_LIMBS_ERITEMA_R1: 0,
      PASI_SUPERIOR_LIMBS_ERITEMA_R2: 1,
      PASI_SUPERIOR_LIMBS_ERITEMA_R3: 2,
      PASI_SUPERIOR_LIMBS_ERITEMA_R4: 3,
      PASI_SUPERIOR_LIMBS_ERITEMA_R5: 4,
    },
    PASI_SUPERIOR_LIMBS_PICOR: {
      PASI_SUPERIOR_LIMBS_PICOR_R1: 0,
      PASI_SUPERIOR_LIMBS_PICOR_R2: 1,
      PASI_SUPERIOR_LIMBS_PICOR_R3: 2,
      PASI_SUPERIOR_LIMBS_PICOR_R4: 3,
      PASI_SUPERIOR_LIMBS_PICOR_R5: 4,
    },
    PASI_SUPERIOR_LIMBS_DESCAMACION: {
      PASI_SUPERIOR_LIMBS_DESCAMACION_R1: 0,
      PASI_SUPERIOR_LIMBS_DESCAMACION_R2: 1,
      PASI_SUPERIOR_LIMBS_DESCAMACION_R3: 2,
      PASI_SUPERIOR_LIMBS_DESCAMACION_R4: 3,
      PASI_SUPERIOR_LIMBS_DESCAMACION_R5: 4,
    },
    PASI_SUPERIOR_LIMBS_INDURACION: {
      PASI_SUPERIOR_LIMBS_INDURACION_R1: 0,
      PASI_SUPERIOR_LIMBS_INDURACION_R2: 1,
      PASI_SUPERIOR_LIMBS_INDURACION_R3: 2,
      PASI_SUPERIOR_LIMBS_INDURACION_R4: 3,
      PASI_SUPERIOR_LIMBS_INDURACION_R5: 4,
    },
  },
  LOWER_LIMBS: {
    PASI_LOWER_LIMBS_AREA: {
      PASI_LOWER_LIMBS_AREA_R1: 0,
      PASI_LOWER_LIMBS_AREA_R2: 1,
      PASI_LOWER_LIMBS_AREA_R3: 2,
      PASI_LOWER_LIMBS_AREA_R4: 3,
      PASI_LOWER_LIMBS_AREA_R5: 4,
      PASI_LOWER_LIMBS_AREA_R6: 5,
      PASI_LOWER_LIMBS_AREA_R7: 6,
    },
    PASI_LOWER_LIMBS_ERITEMA: {
      PASI_LOWER_LIMBS_ERITEMA_R1: 0,
      PASI_LOWER_LIMBS_ERITEMA_R2: 1,
      PASI_LOWER_LIMBS_ERITEMA_R3: 2,
      PASI_LOWER_LIMBS_ERITEMA_R4: 3,
      PASI_LOWER_LIMBS_ERITEMA_R5: 4,
    },
    PASI_LOWER_LIMBS_PICOR: {
      PASI_LOWER_LIMBS_PICOR_R1: 0,
      PASI_LOWER_LIMBS_PICOR_R2: 1,
      PASI_LOWER_LIMBS_PICOR_R3: 2,
      PASI_LOWER_LIMBS_PICOR_R4: 3,
      PASI_LOWER_LIMBS_PICOR_R5: 4,
    },
    PASI_LOWER_LIMBS_DESCAMACION: {
      PASI_LOWER_LIMBS_DESCAMACION_R1: 0,
      PASI_LOWER_LIMBS_DESCAMACION_R2: 1,
      PASI_LOWER_LIMBS_DESCAMACION_R3: 2,
      PASI_LOWER_LIMBS_DESCAMACION_R4: 3,
      PASI_LOWER_LIMBS_DESCAMACION_R5: 4,
    },
    PASI_LOWER_LIMBS_INDURACION: {
      PASI_LOWER_LIMBS_INDURACION_R1: 0,
      PASI_LOWER_LIMBS_INDURACION_R2: 1,
      PASI_LOWER_LIMBS_INDURACION_R3: 2,
      PASI_LOWER_LIMBS_INDURACION_R4: 3,
      PASI_LOWER_LIMBS_INDURACION_R5: 4,
    },
  },
};

export const UAS7 = {
  SEMANA1: {
    DIA1: {
      RONCHAS: {
        UAS7_SEMANA_1_DIA1_RONCHAS_R1: 0,
        UAS7_SEMANA_1_DIA1_RONCHAS_R2: 1,
        UAS7_SEMANA_1_DIA1_RONCHAS_R3: 2,
        UAS7_SEMANA_1_DIA1_RONCHAS_R4: 3,
      },
      PICAZON: {
        UAS7_SEMANA_1_DIA1_PICAZON_R1: 0,
        UAS7_SEMANA_1_DIA1_PICAZON_R2: 1,
        UAS7_SEMANA_1_DIA1_PICAZON_R3: 2,
        UAS7_SEMANA_1_DIA1_PICAZON_R4: 3,
      },
    },
    DIA2: {
      RONCHAS: {
        UAS7_SEMANA_1_DIA2_RONCHAS_R1: 0,
        UAS7_SEMANA_1_DIA2_RONCHAS_R2: 1,
        UAS7_SEMANA_1_DIA2_RONCHAS_R3: 2,
        UAS7_SEMANA_1_DIA2_RONCHAS_R4: 3,
      },
      PICAZON: {
        UAS7_SEMANA_1_DIA2_PICAZON_R1: 0,
        UAS7_SEMANA_1_DIA2_PICAZON_R2: 1,
        UAS7_SEMANA_1_DIA2_PICAZON_R3: 2,
        UAS7_SEMANA_1_DIA2_PICAZON_R4: 3,
      },
    },
    DIA3: {
      RONCHAS: {
        UAS7_SEMANA_1_DIA3_RONCHAS_R1: 0,
        UAS7_SEMANA_1_DIA3_RONCHAS_R2: 1,
        UAS7_SEMANA_1_DIA3_RONCHAS_R3: 2,
        UAS7_SEMANA_1_DIA3_RONCHAS_R4: 3,
      },
      PICAZON: {
        UAS7_SEMANA_1_DIA3_PICAZON_R1: 0,
        UAS7_SEMANA_1_DIA3_PICAZON_R2: 1,
        UAS7_SEMANA_1_DIA3_PICAZON_R3: 2,
        UAS7_SEMANA_1_DIA3_PICAZON_R4: 3,
      },
    },
    DIA4: {
      RONCHAS: {
        UAS7_SEMANA_1_DIA4_RONCHAS_R1: 0,
        UAS7_SEMANA_1_DIA4_RONCHAS_R2: 1,
        UAS7_SEMANA_1_DIA4_RONCHAS_R3: 2,
        UAS7_SEMANA_1_DIA4_RONCHAS_R4: 3,
      },
      PICAZON: {
        UAS7_SEMANA_1_DIA4_PICAZON_R1: 0,
        UAS7_SEMANA_1_DIA4_PICAZON_R2: 1,
        UAS7_SEMANA_1_DIA4_PICAZON_R3: 2,
        UAS7_SEMANA_1_DIA4_PICAZON_R4: 3,
      },
    },
    DIA5: {
      RONCHAS: {
        UAS7_SEMANA_1_DIA5_RONCHAS_R1: 0,
        UAS7_SEMANA_1_DIA5_RONCHAS_R2: 1,
        UAS7_SEMANA_1_DIA5_RONCHAS_R3: 2,
        UAS7_SEMANA_1_DIA5_RONCHAS_R4: 3,
      },
      PICAZON: {
        UAS7_SEMANA_1_DIA5_PICAZON_R1: 0,
        UAS7_SEMANA_1_DIA5_PICAZON_R2: 1,
        UAS7_SEMANA_1_DIA5_PICAZON_R3: 2,
        UAS7_SEMANA_1_DIA5_PICAZON_R4: 3,
      },
    },
    DIA6: {
      RONCHAS: {
        UAS7_SEMANA_1_DIA6_RONCHAS_R1: 0,
        UAS7_SEMANA_1_DIA6_RONCHAS_R2: 1,
        UAS7_SEMANA_1_DIA6_RONCHAS_R3: 2,
        UAS7_SEMANA_1_DIA6_RONCHAS_R4: 3,
      },
      PICAZON: {
        UAS7_SEMANA_1_DIA6_PICAZON_R1: 0,
        UAS7_SEMANA_1_DIA6_PICAZON_R2: 1,
        UAS7_SEMANA_1_DIA6_PICAZON_R3: 2,
        UAS7_SEMANA_1_DIA6_PICAZON_R4: 3,
      },
    },
    DIA7: {
      RONCHAS: {
        UAS7_SEMANA_1_DIA7_RONCHAS_R1: 0,
        UAS7_SEMANA_1_DIA7_RONCHAS_R2: 1,
        UAS7_SEMANA_1_DIA7_RONCHAS_R3: 2,
        UAS7_SEMANA_1_DIA7_RONCHAS_R4: 3,
      },
      PICAZON: {
        UAS7_SEMANA_1_DIA7_PICAZON_R1: 0,
        UAS7_SEMANA_1_DIA7_PICAZON_R2: 1,
        UAS7_SEMANA_1_DIA7_PICAZON_R3: 2,
        UAS7_SEMANA_1_DIA7_PICAZON_R4: 3,
      },
    },
  },
  SEMANA2: {
    DIA1: {
      RONCHAS: {
        UAS7_SEMANA_2_DIA1_RONCHAS_R1: 0,
        UAS7_SEMANA_2_DIA1_RONCHAS_R2: 1,
        UAS7_SEMANA_2_DIA1_RONCHAS_R3: 2,
        UAS7_SEMANA_2_DIA1_RONCHAS_R4: 3,
      },
      PICAZON: {
        UAS7_SEMANA_2_DIA1_PICAZON_R1: 0,
        UAS7_SEMANA_2_DIA1_PICAZON_R2: 1,
        UAS7_SEMANA_2_DIA1_PICAZON_R3: 2,
        UAS7_SEMANA_2_DIA1_PICAZON_R4: 3,
      },
    },
    DIA2: {
      RONCHAS: {
        UAS7_SEMANA_2_DIA2_RONCHAS_R1: 0,
        UAS7_SEMANA_2_DIA2_RONCHAS_R2: 1,
        UAS7_SEMANA_2_DIA2_RONCHAS_R3: 2,
        UAS7_SEMANA_2_DIA2_RONCHAS_R4: 3,
      },
      PICAZON: {
        UAS7_SEMANA_2_DIA2_PICAZON_R1: 0,
        UAS7_SEMANA_2_DIA2_PICAZON_R2: 1,
        UAS7_SEMANA_2_DIA2_PICAZON_R3: 2,
        UAS7_SEMANA_2_DIA2_PICAZON_R4: 3,
      },
    },
    DIA3: {
      RONCHAS: {
        UAS7_SEMANA_2_DIA3_RONCHAS_R1: 0,
        UAS7_SEMANA_2_DIA3_RONCHAS_R2: 1,
        UAS7_SEMANA_2_DIA3_RONCHAS_R3: 2,
        UAS7_SEMANA_2_DIA3_RONCHAS_R4: 3,
      },
      PICAZON: {
        UAS7_SEMANA_2_DIA3_PICAZON_R1: 0,
        UAS7_SEMANA_2_DIA3_PICAZON_R2: 1,
        UAS7_SEMANA_2_DIA3_PICAZON_R3: 2,
        UAS7_SEMANA_2_DIA3_PICAZON_R4: 3,
      },
    },
    DIA4: {
      RONCHAS: {
        UAS7_SEMANA_2_DIA4_RONCHAS_R1: 0,
        UAS7_SEMANA_2_DIA4_RONCHAS_R2: 1,
        UAS7_SEMANA_2_DIA4_RONCHAS_R3: 2,
        UAS7_SEMANA_2_DIA4_RONCHAS_R4: 3,
      },
      PICAZON: {
        UAS7_SEMANA_2_DIA4_PICAZON_R1: 0,
        UAS7_SEMANA_2_DIA4_PICAZON_R2: 1,
        UAS7_SEMANA_2_DIA4_PICAZON_R3: 2,
        UAS7_SEMANA_2_DIA4_PICAZON_R4: 3,
      },
    },
    DIA5: {
      RONCHAS: {
        UAS7_SEMANA_2_DIA5_RONCHAS_R1: 0,
        UAS7_SEMANA_2_DIA5_RONCHAS_R2: 1,
        UAS7_SEMANA_2_DIA5_RONCHAS_R3: 2,
        UAS7_SEMANA_2_DIA5_RONCHAS_R4: 3,
      },
      PICAZON: {
        UAS7_SEMANA_2_DIA5_PICAZON_R1: 0,
        UAS7_SEMANA_2_DIA5_PICAZON_R2: 1,
        UAS7_SEMANA_2_DIA5_PICAZON_R3: 2,
        UAS7_SEMANA_2_DIA5_PICAZON_R4: 3,
      },
    },
    DIA6: {
      RONCHAS: {
        UAS7_SEMANA_2_DIA6_RONCHAS_R1: 0,
        UAS7_SEMANA_2_DIA6_RONCHAS_R2: 1,
        UAS7_SEMANA_2_DIA6_RONCHAS_R3: 2,
        UAS7_SEMANA_2_DIA6_RONCHAS_R4: 3,
      },
      PICAZON: {
        UAS7_SEMANA_2_DIA6_PICAZON_R1: 0,
        UAS7_SEMANA_2_DIA6_PICAZON_R2: 1,
        UAS7_SEMANA_2_DIA6_PICAZON_R3: 2,
        UAS7_SEMANA_2_DIA6_PICAZON_R4: 3,
      },
    },
    DIA7: {
      RONCHAS: {
        UAS7_SEMANA_2_DIA7_RONCHAS_R1: 0,
        UAS7_SEMANA_2_DIA7_RONCHAS_R2: 1,
        UAS7_SEMANA_2_DIA7_RONCHAS_R3: 2,
        UAS7_SEMANA_2_DIA7_RONCHAS_R4: 3,
      },
      PICAZON: {
        UAS7_SEMANA_2_DIA7_PICAZON_R1: 0,
        UAS7_SEMANA_2_DIA7_PICAZON_R2: 1,
        UAS7_SEMANA_2_DIA7_PICAZON_R3: 2,
        UAS7_SEMANA_2_DIA7_PICAZON_R4: 3,
      },
    },
  },
  SEMANA3: {
    DIA1: {
      RONCHAS: {
        UAS7_SEMANA_3_DIA1_RONCHAS_R1: 0,
        UAS7_SEMANA_3_DIA1_RONCHAS_R2: 1,
        UAS7_SEMANA_3_DIA1_RONCHAS_R3: 2,
        UAS7_SEMANA_3_DIA1_RONCHAS_R4: 3,
      },
      PICAZON: {
        UAS7_SEMANA_3_DIA1_PICAZON_R1: 0,
        UAS7_SEMANA_3_DIA1_PICAZON_R2: 1,
        UAS7_SEMANA_3_DIA1_PICAZON_R3: 2,
        UAS7_SEMANA_3_DIA1_PICAZON_R4: 3,
      },
    },
    DIA2: {
      RONCHAS: {
        UAS7_SEMANA_3_DIA2_RONCHAS_R1: 0,
        UAS7_SEMANA_3_DIA2_RONCHAS_R2: 1,
        UAS7_SEMANA_3_DIA2_RONCHAS_R3: 2,
        UAS7_SEMANA_3_DIA2_RONCHAS_R4: 3,
      },
      PICAZON: {
        UAS7_SEMANA_3_DIA2_PICAZON_R1: 0,
        UAS7_SEMANA_3_DIA2_PICAZON_R2: 1,
        UAS7_SEMANA_3_DIA2_PICAZON_R3: 2,
        UAS7_SEMANA_3_DIA2_PICAZON_R4: 3,
      },
    },
    DIA3: {
      RONCHAS: {
        UAS7_SEMANA_3_DIA3_RONCHAS_R1: 0,
        UAS7_SEMANA_3_DIA3_RONCHAS_R2: 1,
        UAS7_SEMANA_3_DIA3_RONCHAS_R3: 2,
        UAS7_SEMANA_3_DIA3_RONCHAS_R4: 3,
      },
      PICAZON: {
        UAS7_SEMANA_3_DIA3_PICAZON_R1: 0,
        UAS7_SEMANA_3_DIA3_PICAZON_R2: 1,
        UAS7_SEMANA_3_DIA3_PICAZON_R3: 2,
        UAS7_SEMANA_3_DIA3_PICAZON_R4: 3,
      },
    },
    DIA4: {
      RONCHAS: {
        UAS7_SEMANA_3_DIA4_RONCHAS_R1: 0,
        UAS7_SEMANA_3_DIA4_RONCHAS_R2: 1,
        UAS7_SEMANA_3_DIA4_RONCHAS_R3: 2,
        UAS7_SEMANA_3_DIA4_RONCHAS_R4: 3,
      },
      PICAZON: {
        UAS7_SEMANA_3_DIA4_PICAZON_R1: 0,
        UAS7_SEMANA_3_DIA4_PICAZON_R2: 1,
        UAS7_SEMANA_3_DIA4_PICAZON_R3: 2,
        UAS7_SEMANA_3_DIA4_PICAZON_R4: 3,
      },
    },
    DIA5: {
      RONCHAS: {
        UAS7_SEMANA_3_DIA5_RONCHAS_R1: 0,
        UAS7_SEMANA_3_DIA5_RONCHAS_R2: 1,
        UAS7_SEMANA_3_DIA5_RONCHAS_R3: 2,
        UAS7_SEMANA_3_DIA5_RONCHAS_R4: 3,
      },
      PICAZON: {
        UAS7_SEMANA_3_DIA5_PICAZON_R1: 0,
        UAS7_SEMANA_3_DIA5_PICAZON_R2: 1,
        UAS7_SEMANA_3_DIA5_PICAZON_R3: 2,
        UAS7_SEMANA_3_DIA5_PICAZON_R4: 3,
      },
    },
    DIA6: {
      RONCHAS: {
        UAS7_SEMANA_3_DIA6_RONCHAS_R1: 0,
        UAS7_SEMANA_3_DIA6_RONCHAS_R2: 1,
        UAS7_SEMANA_3_DIA6_RONCHAS_R3: 2,
        UAS7_SEMANA_3_DIA6_RONCHAS_R4: 3,
      },
      PICAZON: {
        UAS7_SEMANA_3_DIA6_PICAZON_R1: 0,
        UAS7_SEMANA_3_DIA6_PICAZON_R2: 1,
        UAS7_SEMANA_3_DIA6_PICAZON_R3: 2,
        UAS7_SEMANA_3_DIA6_PICAZON_R4: 3,
      },
    },
    DIA7: {
      RONCHAS: {
        UAS7_SEMANA_3_DIA7_RONCHAS_R1: 0,
        UAS7_SEMANA_3_DIA7_RONCHAS_R2: 1,
        UAS7_SEMANA_3_DIA7_RONCHAS_R3: 2,
        UAS7_SEMANA_3_DIA7_RONCHAS_R4: 3,
      },
      PICAZON: {
        UAS7_SEMANA_3_DIA7_PICAZON_R1: 0,
        UAS7_SEMANA_3_DIA7_PICAZON_R2: 1,
        UAS7_SEMANA_3_DIA7_PICAZON_R3: 2,
        UAS7_SEMANA_3_DIA7_PICAZON_R4: 3,
      },
    },
  },
  SEMANA4: {
    DIA1: {
      RONCHAS: {
        UAS7_SEMANA_4_DIA1_RONCHAS_R1: 0,
        UAS7_SEMANA_4_DIA1_RONCHAS_R2: 1,
        UAS7_SEMANA_4_DIA1_RONCHAS_R3: 2,
        UAS7_SEMANA_4_DIA1_RONCHAS_R4: 3,
      },
      PICAZON: {
        UAS7_SEMANA_4_DIA1_PICAZON_R1: 0,
        UAS7_SEMANA_4_DIA1_PICAZON_R2: 1,
        UAS7_SEMANA_4_DIA1_PICAZON_R3: 2,
        UAS7_SEMANA_4_DIA1_PICAZON_R4: 3,
      },
    },
    DIA2: {
      RONCHAS: {
        UAS7_SEMANA_4_DIA2_RONCHAS_R1: 0,
        UAS7_SEMANA_4_DIA2_RONCHAS_R2: 1,
        UAS7_SEMANA_4_DIA2_RONCHAS_R3: 2,
        UAS7_SEMANA_4_DIA2_RONCHAS_R4: 3,
      },
      PICAZON: {
        UAS7_SEMANA_4_DIA2_PICAZON_R1: 0,
        UAS7_SEMANA_4_DIA2_PICAZON_R2: 1,
        UAS7_SEMANA_4_DIA2_PICAZON_R3: 2,
        UAS7_SEMANA_4_DIA2_PICAZON_R4: 3,
      },
    },
    DIA3: {
      RONCHAS: {
        UAS7_SEMANA_4_DIA3_RONCHAS_R1: 0,
        UAS7_SEMANA_4_DIA3_RONCHAS_R2: 1,
        UAS7_SEMANA_4_DIA3_RONCHAS_R3: 2,
        UAS7_SEMANA_4_DIA3_RONCHAS_R4: 3,
      },
      PICAZON: {
        UAS7_SEMANA_4_DIA3_PICAZON_R1: 0,
        UAS7_SEMANA_4_DIA3_PICAZON_R2: 1,
        UAS7_SEMANA_4_DIA3_PICAZON_R3: 2,
        UAS7_SEMANA_4_DIA3_PICAZON_R4: 3,
      },
    },
    DIA4: {
      RONCHAS: {
        UAS7_SEMANA_4_DIA4_RONCHAS_R1: 0,
        UAS7_SEMANA_4_DIA4_RONCHAS_R2: 1,
        UAS7_SEMANA_4_DIA4_RONCHAS_R3: 2,
        UAS7_SEMANA_4_DIA4_RONCHAS_R4: 3,
      },
      PICAZON: {
        UAS7_SEMANA_4_DIA4_PICAZON_R1: 0,
        UAS7_SEMANA_4_DIA4_PICAZON_R2: 1,
        UAS7_SEMANA_4_DIA4_PICAZON_R3: 2,
        UAS7_SEMANA_4_DIA4_PICAZON_R4: 3,
      },
    },
    DIA5: {
      RONCHAS: {
        UAS7_SEMANA_4_DIA5_RONCHAS_R1: 0,
        UAS7_SEMANA_4_DIA5_RONCHAS_R2: 1,
        UAS7_SEMANA_4_DIA5_RONCHAS_R3: 2,
        UAS7_SEMANA_4_DIA5_RONCHAS_R4: 3,
      },
      PICAZON: {
        UAS7_SEMANA_4_DIA5_PICAZON_R1: 0,
        UAS7_SEMANA_4_DIA5_PICAZON_R2: 1,
        UAS7_SEMANA_4_DIA5_PICAZON_R3: 2,
        UAS7_SEMANA_4_DIA5_PICAZON_R4: 3,
      },
    },
    DIA6: {
      RONCHAS: {
        UAS7_SEMANA_4_DIA6_RONCHAS_R1: 0,
        UAS7_SEMANA_4_DIA6_RONCHAS_R2: 1,
        UAS7_SEMANA_4_DIA6_RONCHAS_R3: 2,
        UAS7_SEMANA_4_DIA6_RONCHAS_R4: 3,
      },
      PICAZON: {
        UAS7_SEMANA_4_DIA6_PICAZON_R1: 0,
        UAS7_SEMANA_4_DIA6_PICAZON_R2: 1,
        UAS7_SEMANA_4_DIA6_PICAZON_R3: 2,
        UAS7_SEMANA_4_DIA6_PICAZON_R4: 3,
      },
    },
    DIA7: {
      RONCHAS: {
        UAS7_SEMANA_4_DIA7_RONCHAS_R1: 0,
        UAS7_SEMANA_4_DIA7_RONCHAS_R2: 1,
        UAS7_SEMANA_4_DIA7_RONCHAS_R3: 2,
        UAS7_SEMANA_4_DIA7_RONCHAS_R4: 3,
      },
      PICAZON: {
        UAS7_SEMANA_4_DIA7_PICAZON_R1: 0,
        UAS7_SEMANA_4_DIA7_PICAZON_R2: 1,
        UAS7_SEMANA_4_DIA7_PICAZON_R3: 2,
        UAS7_SEMANA_4_DIA7_PICAZON_R4: 3,
      },
    },
  },
};

export const NAPSI = {
  MANOS: {
    MATRIX: {
      NAPSI_MANOS_DEDO1_MATRIX: {
        NAPSI_MANOS_DEDO1_MATRIX_R1: 0,
        NAPSI_MANOS_DEDO1_MATRIX_R2: 1,
        NAPSI_MANOS_DEDO1_MATRIX_R3: 2,
        NAPSI_MANOS_DEDO1_MATRIX_R4: 3,
        NAPSI_MANOS_DEDO1_MATRIX_R5: 4,
      },
      NAPSI_MANOS_DEDO2_MATRIX: {
        NAPSI_MANOS_DEDO2_MATRIX_R1: 0,
        NAPSI_MANOS_DEDO2_MATRIX_R2: 1,
        NAPSI_MANOS_DEDO2_MATRIX_R3: 2,
        NAPSI_MANOS_DEDO2_MATRIX_R4: 3,
        NAPSI_MANOS_DEDO2_MATRIX_R5: 4,
      },
      NAPSI_MANOS_DEDO3_MATRIX: {
        NAPSI_MANOS_DEDO3_MATRIX_R1: 0,
        NAPSI_MANOS_DEDO3_MATRIX_R2: 1,
        NAPSI_MANOS_DEDO3_MATRIX_R3: 2,
        NAPSI_MANOS_DEDO3_MATRIX_R4: 3,
        NAPSI_MANOS_DEDO3_MATRIX_R5: 4,
      },
      NAPSI_MANOS_DEDO4_MATRIX: {
        NAPSI_MANOS_DEDO4_MATRIX_R1: 0,
        NAPSI_MANOS_DEDO4_MATRIX_R2: 1,
        NAPSI_MANOS_DEDO4_MATRIX_R3: 2,
        NAPSI_MANOS_DEDO4_MATRIX_R4: 3,
        NAPSI_MANOS_DEDO4_MATRIX_R5: 4,
      },
      NAPSI_MANOS_DEDO5_MATRIX: {
        NAPSI_MANOS_DEDO5_MATRIX_R1: 0,
        NAPSI_MANOS_DEDO5_MATRIX_R2: 1,
        NAPSI_MANOS_DEDO5_MATRIX_R3: 2,
        NAPSI_MANOS_DEDO5_MATRIX_R4: 3,
        NAPSI_MANOS_DEDO5_MATRIX_R5: 4,
      },
      NAPSI_MANOS_DEDO6_MATRIX: {
        NAPSI_MANOS_DEDO6_MATRIX_R1: 0,
        NAPSI_MANOS_DEDO6_MATRIX_R2: 1,
        NAPSI_MANOS_DEDO6_MATRIX_R3: 2,
        NAPSI_MANOS_DEDO6_MATRIX_R4: 3,
        NAPSI_MANOS_DEDO6_MATRIX_R5: 4,
      },
      NAPSI_MANOS_DEDO7_MATRIX: {
        NAPSI_MANOS_DEDO7_MATRIX_R1: 0,
        NAPSI_MANOS_DEDO7_MATRIX_R2: 1,
        NAPSI_MANOS_DEDO7_MATRIX_R3: 2,
        NAPSI_MANOS_DEDO7_MATRIX_R4: 3,
        NAPSI_MANOS_DEDO7_MATRIX_R5: 4,
      },
      NAPSI_MANOS_DEDO8_MATRIX: {
        NAPSI_MANOS_DEDO8_MATRIX_R1: 0,
        NAPSI_MANOS_DEDO8_MATRIX_R2: 1,
        NAPSI_MANOS_DEDO8_MATRIX_R3: 2,
        NAPSI_MANOS_DEDO8_MATRIX_R4: 3,
        NAPSI_MANOS_DEDO8_MATRIX_R5: 4,
      },
      NAPSI_MANOS_DEDO9_MATRIX: {
        NAPSI_MANOS_DEDO9_MATRIX_R1: 0,
        NAPSI_MANOS_DEDO9_MATRIX_R2: 1,
        NAPSI_MANOS_DEDO9_MATRIX_R3: 2,
        NAPSI_MANOS_DEDO9_MATRIX_R4: 3,
        NAPSI_MANOS_DEDO9_MATRIX_R5: 4,
      },
      NAPSI_MANOS_DEDO10_MATRIX: {
        NAPSI_MANOS_DEDO10_MATRIX_R1: 0,
        NAPSI_MANOS_DEDO10_MATRIX_R2: 1,
        NAPSI_MANOS_DEDO10_MATRIX_R3: 2,
        NAPSI_MANOS_DEDO10_MATRIX_R4: 3,
        NAPSI_MANOS_DEDO10_MATRIX_R5: 4,
      },
    },
    LECHO: {
      NAPSI_MANOS_DEDO1_LECHO: {
        NAPSI_MANOS_DEDO1_LECHO_R1: 0,
        NAPSI_MANOS_DEDO1_LECHO_R2: 1,
        NAPSI_MANOS_DEDO1_LECHO_R3: 2,
        NAPSI_MANOS_DEDO1_LECHO_R4: 3,
        NAPSI_MANOS_DEDO1_LECHO_R5: 4,
      },
      NAPSI_MANOS_DEDO2_LECHO: {
        NAPSI_MANOS_DEDO2_LECHO_R1: 0,
        NAPSI_MANOS_DEDO2_LECHO_R2: 1,
        NAPSI_MANOS_DEDO2_LECHO_R3: 2,
        NAPSI_MANOS_DEDO2_LECHO_R4: 3,
        NAPSI_MANOS_DEDO2_LECHO_R5: 4,
      },
      NAPSI_MANOS_DEDO3_LECHO: {
        NAPSI_MANOS_DEDO3_LECHO_R1: 0,
        NAPSI_MANOS_DEDO3_LECHO_R2: 1,
        NAPSI_MANOS_DEDO3_LECHO_R3: 2,
        NAPSI_MANOS_DEDO3_LECHO_R4: 3,
        NAPSI_MANOS_DEDO3_LECHO_R5: 4,
      },
      NAPSI_MANOS_DEDO4_LECHO: {
        NAPSI_MANOS_DEDO4_LECHO_R1: 0,
        NAPSI_MANOS_DEDO4_LECHO_R2: 1,
        NAPSI_MANOS_DEDO4_LECHO_R3: 2,
        NAPSI_MANOS_DEDO4_LECHO_R4: 3,
        NAPSI_MANOS_DEDO4_LECHO_R5: 4,
      },
      NAPSI_MANOS_DEDO5_LECHO: {
        NAPSI_MANOS_DEDO5_LECHO_R1: 0,
        NAPSI_MANOS_DEDO5_LECHO_R2: 1,
        NAPSI_MANOS_DEDO5_LECHO_R3: 2,
        NAPSI_MANOS_DEDO5_LECHO_R4: 3,
        NAPSI_MANOS_DEDO5_LECHO_R5: 4,
      },
      NAPSI_MANOS_DEDO6_LECHO: {
        NAPSI_MANOS_DEDO6_LECHO_R1: 0,
        NAPSI_MANOS_DEDO6_LECHO_R2: 1,
        NAPSI_MANOS_DEDO6_LECHO_R3: 2,
        NAPSI_MANOS_DEDO6_LECHO_R4: 3,
        NAPSI_MANOS_DEDO6_LECHO_R5: 4,
      },
      NAPSI_MANOS_DEDO7_LECHO: {
        NAPSI_MANOS_DEDO7_LECHO_R1: 0,
        NAPSI_MANOS_DEDO7_LECHO_R2: 1,
        NAPSI_MANOS_DEDO7_LECHO_R3: 2,
        NAPSI_MANOS_DEDO7_LECHO_R4: 3,
        NAPSI_MANOS_DEDO7_LECHO_R5: 4,
      },
      NAPSI_MANOS_DEDO8_LECHO: {
        NAPSI_MANOS_DEDO8_LECHO_R1: 0,
        NAPSI_MANOS_DEDO8_LECHO_R2: 1,
        NAPSI_MANOS_DEDO8_LECHO_R3: 2,
        NAPSI_MANOS_DEDO8_LECHO_R4: 3,
        NAPSI_MANOS_DEDO8_LECHO_R5: 4,
      },
      NAPSI_MANOS_DEDO9_LECHO: {
        NAPSI_MANOS_DEDO9_LECHO_R1: 0,
        NAPSI_MANOS_DEDO9_LECHO_R2: 1,
        NAPSI_MANOS_DEDO9_LECHO_R3: 2,
        NAPSI_MANOS_DEDO9_LECHO_R4: 3,
        NAPSI_MANOS_DEDO9_LECHO_R5: 4,
      },
      NAPSI_MANOS_DEDO10_LECHO: {
        NAPSI_MANOS_DEDO10_LECHO_R1: 0,
        NAPSI_MANOS_DEDO10_LECHO_R2: 1,
        NAPSI_MANOS_DEDO10_LECHO_R3: 2,
        NAPSI_MANOS_DEDO10_LECHO_R4: 3,
        NAPSI_MANOS_DEDO10_LECHO_R5: 4,
      },
    },
  },
  PIES: {
    MATRIX: {
      NAPSI_PIES_DEDO1_MATRIX: {
        NAPSI_PIES_DEDO1_MATRIX_R1: 0,
        NAPSI_PIES_DEDO1_MATRIX_R2: 1,
        NAPSI_PIES_DEDO1_MATRIX_R3: 2,
        NAPSI_PIES_DEDO1_MATRIX_R4: 3,
        NAPSI_PIES_DEDO1_MATRIX_R5: 4,
      },
      NAPSI_PIES_DEDO2_MATRIX: {
        NAPSI_PIES_DEDO2_MATRIX_R1: 0,
        NAPSI_PIES_DEDO2_MATRIX_R2: 1,
        NAPSI_PIES_DEDO2_MATRIX_R3: 2,
        NAPSI_PIES_DEDO2_MATRIX_R4: 3,
        NAPSI_PIES_DEDO2_MATRIX_R5: 4,
      },
      NAPSI_PIES_DEDO3_MATRIX: {
        NAPSI_PIES_DEDO3_MATRIX_R1: 0,
        NAPSI_PIES_DEDO3_MATRIX_R2: 1,
        NAPSI_PIES_DEDO3_MATRIX_R3: 2,
        NAPSI_PIES_DEDO3_MATRIX_R4: 3,
        NAPSI_PIES_DEDO3_MATRIX_R5: 4,
      },
      NAPSI_PIES_DEDO4_MATRIX: {
        NAPSI_PIES_DEDO4_MATRIX_R1: 0,
        NAPSI_PIES_DEDO4_MATRIX_R2: 1,
        NAPSI_PIES_DEDO4_MATRIX_R3: 2,
        NAPSI_PIES_DEDO4_MATRIX_R4: 3,
        NAPSI_PIES_DEDO4_MATRIX_R5: 4,
      },
      NAPSI_PIES_DEDO5_MATRIX: {
        NAPSI_PIES_DEDO5_MATRIX_R1: 0,
        NAPSI_PIES_DEDO5_MATRIX_R2: 1,
        NAPSI_PIES_DEDO5_MATRIX_R3: 2,
        NAPSI_PIES_DEDO5_MATRIX_R4: 3,
        NAPSI_PIES_DEDO5_MATRIX_R5: 4,
      },
      NAPSI_PIES_DEDO6_MATRIX: {
        NAPSI_PIES_DEDO6_MATRIX_R1: 0,
        NAPSI_PIES_DEDO6_MATRIX_R2: 1,
        NAPSI_PIES_DEDO6_MATRIX_R3: 2,
        NAPSI_PIES_DEDO6_MATRIX_R4: 3,
        NAPSI_PIES_DEDO6_MATRIX_R5: 4,
      },
      NAPSI_PIES_DEDO7_MATRIX: {
        NAPSI_PIES_DEDO7_MATRIX_R1: 0,
        NAPSI_PIES_DEDO7_MATRIX_R2: 1,
        NAPSI_PIES_DEDO7_MATRIX_R3: 2,
        NAPSI_PIES_DEDO7_MATRIX_R4: 3,
        NAPSI_PIES_DEDO7_MATRIX_R5: 4,
      },
      NAPSI_PIES_DEDO8_MATRIX: {
        NAPSI_PIES_DEDO8_MATRIX_R1: 0,
        NAPSI_PIES_DEDO8_MATRIX_R2: 1,
        NAPSI_PIES_DEDO8_MATRIX_R3: 2,
        NAPSI_PIES_DEDO8_MATRIX_R4: 3,
        NAPSI_PIES_DEDO8_MATRIX_R5: 4,
      },
      NAPSI_PIES_DEDO9_MATRIX: {
        NAPSI_PIES_DEDO9_MATRIX_R1: 0,
        NAPSI_PIES_DEDO9_MATRIX_R2: 1,
        NAPSI_PIES_DEDO9_MATRIX_R3: 2,
        NAPSI_PIES_DEDO9_MATRIX_R4: 3,
        NAPSI_PIES_DEDO9_MATRIX_R5: 4,
      },
      NAPSI_PIES_DEDO10_MATRIX: {
        NAPSI_PIES_DEDO10_MATRIX_R1: 0,
        NAPSI_PIES_DEDO10_MATRIX_R2: 1,
        NAPSI_PIES_DEDO10_MATRIX_R3: 2,
        NAPSI_PIES_DEDO10_MATRIX_R4: 3,
        NAPSI_PIES_DEDO10_MATRIX_R5: 4,
      },
    },
    LECHO: {
      NAPSI_PIES_DEDO1_LECHO: {
        NAPSI_PIES_DEDO1_LECHO_R1: 0,
        NAPSI_PIES_DEDO1_LECHO_R2: 1,
        NAPSI_PIES_DEDO1_LECHO_R3: 2,
        NAPSI_PIES_DEDO1_LECHO_R4: 3,
        NAPSI_PIES_DEDO1_LECHO_R5: 4,
      },
      NAPSI_PIES_DEDO2_LECHO: {
        NAPSI_PIES_DEDO2_LECHO_R1: 0,
        NAPSI_PIES_DEDO2_LECHO_R2: 1,
        NAPSI_PIES_DEDO2_LECHO_R3: 2,
        NAPSI_PIES_DEDO2_LECHO_R4: 3,
        NAPSI_PIES_DEDO2_LECHO_R5: 4,
      },
      NAPSI_PIES_DEDO3_LECHO: {
        NAPSI_PIES_DEDO3_LECHO_R1: 0,
        NAPSI_PIES_DEDO3_LECHO_R2: 1,
        NAPSI_PIES_DEDO3_LECHO_R3: 2,
        NAPSI_PIES_DEDO3_LECHO_R4: 3,
        NAPSI_PIES_DEDO3_LECHO_R5: 4,
      },
      NAPSI_PIES_DEDO4_LECHO: {
        NAPSI_PIES_DEDO4_LECHO_R1: 0,
        NAPSI_PIES_DEDO4_LECHO_R2: 1,
        NAPSI_PIES_DEDO4_LECHO_R3: 2,
        NAPSI_PIES_DEDO4_LECHO_R4: 3,
        NAPSI_PIES_DEDO4_LECHO_R5: 4,
      },
      NAPSI_PIES_DEDO5_LECHO: {
        NAPSI_PIES_DEDO5_LECHO_R1: 0,
        NAPSI_PIES_DEDO5_LECHO_R2: 1,
        NAPSI_PIES_DEDO5_LECHO_R3: 2,
        NAPSI_PIES_DEDO5_LECHO_R4: 3,
        NAPSI_PIES_DEDO5_LECHO_R5: 4,
      },
      NAPSI_PIES_DEDO6_LECHO: {
        NAPSI_PIES_DEDO6_LECHO_R1: 0,
        NAPSI_PIES_DEDO6_LECHO_R2: 1,
        NAPSI_PIES_DEDO6_LECHO_R3: 2,
        NAPSI_PIES_DEDO6_LECHO_R4: 3,
        NAPSI_PIES_DEDO6_LECHO_R5: 4,
      },
      NAPSI_PIES_DEDO7_LECHO: {
        NAPSI_PIES_DEDO7_LECHO_R1: 0,
        NAPSI_PIES_DEDO7_LECHO_R2: 1,
        NAPSI_PIES_DEDO7_LECHO_R3: 2,
        NAPSI_PIES_DEDO7_LECHO_R4: 3,
        NAPSI_PIES_DEDO7_LECHO_R5: 4,
      },
      NAPSI_PIES_DEDO8_LECHO: {
        NAPSI_PIES_DEDO8_LECHO_R1: 0,
        NAPSI_PIES_DEDO8_LECHO_R2: 1,
        NAPSI_PIES_DEDO8_LECHO_R3: 2,
        NAPSI_PIES_DEDO8_LECHO_R4: 3,
        NAPSI_PIES_DEDO8_LECHO_R5: 4,
      },
      NAPSI_PIES_DEDO9_LECHO: {
        NAPSI_PIES_DEDO9_LECHO_R1: 0,
        NAPSI_PIES_DEDO9_LECHO_R2: 1,
        NAPSI_PIES_DEDO9_LECHO_R3: 2,
        NAPSI_PIES_DEDO9_LECHO_R4: 3,
        NAPSI_PIES_DEDO9_LECHO_R5: 4,
      },
      NAPSI_PIES_DEDO10_LECHO: {
        NAPSI_PIES_DEDO10_LECHO_R1: 0,
        NAPSI_PIES_DEDO10_LECHO_R2: 1,
        NAPSI_PIES_DEDO10_LECHO_R3: 2,
        NAPSI_PIES_DEDO10_LECHO_R4: 3,
        NAPSI_PIES_DEDO10_LECHO_R5: 4,
      },
    },
  },
};

export const NAPSIMM = {
  ONICOLISIS: {
    NAPSIMM_DEDO1_ONICOLISIS: {
      NAPSIMM_DEDO1_ONICOLISIS_R1: 0,
      NAPSIMM_DEDO1_ONICOLISIS_R2: 1,
      NAPSIMM_DEDO1_ONICOLISIS_R3: 2,
      NAPSIMM_DEDO1_ONICOLISIS_R4: 3,
    },
    NAPSIMM_DEDO2_ONICOLISIS: {
      NAPSIMM_DEDO2_ONICOLISIS_R1: 0,
      NAPSIMM_DEDO2_ONICOLISIS_R2: 1,
      NAPSIMM_DEDO2_ONICOLISIS_R3: 2,
      NAPSIMM_DEDO2_ONICOLISIS_R4: 3,
    },
    NAPSIMM_DEDO3_ONICOLISIS: {
      NAPSIMM_DEDO3_ONICOLISIS_R1: 0,
      NAPSIMM_DEDO3_ONICOLISIS_R2: 1,
      NAPSIMM_DEDO3_ONICOLISIS_R3: 2,
      NAPSIMM_DEDO3_ONICOLISIS_R4: 3,
    },
    NAPSIMM_DEDO4_ONICOLISIS: {
      NAPSIMM_DEDO4_ONICOLISIS_R1: 0,
      NAPSIMM_DEDO4_ONICOLISIS_R2: 1,
      NAPSIMM_DEDO4_ONICOLISIS_R3: 2,
      NAPSIMM_DEDO4_ONICOLISIS_R4: 3,
    },
    NAPSIMM_DEDO5_ONICOLISIS: {
      NAPSIMM_DEDO5_ONICOLISIS_R1: 0,
      NAPSIMM_DEDO5_ONICOLISIS_R2: 1,
      NAPSIMM_DEDO5_ONICOLISIS_R3: 2,
      NAPSIMM_DEDO5_ONICOLISIS_R4: 3,
    },
    NAPSIMM_DEDO6_ONICOLISIS: {
      NAPSIMM_DEDO6_ONICOLISIS_R1: 0,
      NAPSIMM_DEDO6_ONICOLISIS_R2: 1,
      NAPSIMM_DEDO6_ONICOLISIS_R3: 2,
      NAPSIMM_DEDO6_ONICOLISIS_R4: 3,
    },
    NAPSIMM_DEDO7_ONICOLISIS: {
      NAPSIMM_DEDO7_ONICOLISIS_R1: 0,
      NAPSIMM_DEDO7_ONICOLISIS_R2: 1,
      NAPSIMM_DEDO7_ONICOLISIS_R3: 2,
      NAPSIMM_DEDO7_ONICOLISIS_R4: 3,
    },
    NAPSIMM_DEDO8_ONICOLISIS: {
      NAPSIMM_DEDO8_ONICOLISIS_R1: 0,
      NAPSIMM_DEDO8_ONICOLISIS_R2: 1,
      NAPSIMM_DEDO8_ONICOLISIS_R3: 2,
      NAPSIMM_DEDO8_ONICOLISIS_R4: 3,
    },
    NAPSIMM_DEDO9_ONICOLISIS: {
      NAPSIMM_DEDO9_ONICOLISIS_R1: 0,
      NAPSIMM_DEDO9_ONICOLISIS_R2: 1,
      NAPSIMM_DEDO9_ONICOLISIS_R3: 2,
      NAPSIMM_DEDO9_ONICOLISIS_R4: 3,
    },
    NAPSIMM_DEDO10_ONICOLISIS: {
      NAPSIMM_DEDO10_ONICOLISIS_R1: 0,
      NAPSIMM_DEDO10_ONICOLISIS_R2: 1,
      NAPSIMM_DEDO10_ONICOLISIS_R3: 2,
      NAPSIMM_DEDO10_ONICOLISIS_R4: 3,
    },
  },
  PITTS: {
    NAPSIMM_DEDO1_PITTS: {
      NAPSIMM_DEDO1_PITTS_R1: 0,
      NAPSIMM_DEDO1_PITTS_R2: 1,
      NAPSIMM_DEDO1_PITTS_R3: 2,
      NAPSIMM_DEDO1_PITTS_R4: 3,
    },
    NAPSIMM_DEDO2_PITTS: {
      NAPSIMM_DEDO2_PITTS_R1: 0,
      NAPSIMM_DEDO2_PITTS_R2: 1,
      NAPSIMM_DEDO2_PITTS_R3: 2,
      NAPSIMM_DEDO2_PITTS_R4: 3,
    },
    NAPSIMM_DEDO3_PITTS: {
      NAPSIMM_DEDO3_PITTS_R1: 0,
      NAPSIMM_DEDO3_PITTS_R2: 1,
      NAPSIMM_DEDO3_PITTS_R3: 2,
      NAPSIMM_DEDO3_PITTS_R4: 3,
    },
    NAPSIMM_DEDO4_PITTS: {
      NAPSIMM_DEDO4_PITTS_R1: 0,
      NAPSIMM_DEDO4_PITTS_R2: 1,
      NAPSIMM_DEDO4_PITTS_R3: 2,
      NAPSIMM_DEDO4_PITTS_R4: 3,
    },
    NAPSIMM_DEDO5_PITTS: {
      NAPSIMM_DEDO5_PITTS_R1: 0,
      NAPSIMM_DEDO5_PITTS_R2: 1,
      NAPSIMM_DEDO5_PITTS_R3: 2,
      NAPSIMM_DEDO5_PITTS_R4: 3,
    },
    NAPSIMM_DEDO6_PITTS: {
      NAPSIMM_DEDO6_PITTS_R1: 0,
      NAPSIMM_DEDO6_PITTS_R2: 1,
      NAPSIMM_DEDO6_PITTS_R3: 2,
      NAPSIMM_DEDO6_PITTS_R4: 3,
    },
    NAPSIMM_DEDO7_PITTS: {
      NAPSIMM_DEDO7_PITTS_R1: 0,
      NAPSIMM_DEDO7_PITTS_R2: 1,
      NAPSIMM_DEDO7_PITTS_R3: 2,
      NAPSIMM_DEDO7_PITTS_R4: 3,
    },
    NAPSIMM_DEDO8_PITTS: {
      NAPSIMM_DEDO8_PITTS_R1: 0,
      NAPSIMM_DEDO8_PITTS_R2: 1,
      NAPSIMM_DEDO8_PITTS_R3: 2,
      NAPSIMM_DEDO8_PITTS_R4: 3,
    },
    NAPSIMM_DEDO9_PITTS: {
      NAPSIMM_DEDO9_PITTS_R1: 0,
      NAPSIMM_DEDO9_PITTS_R2: 1,
      NAPSIMM_DEDO9_PITTS_R3: 2,
      NAPSIMM_DEDO9_PITTS_R4: 3,
    },
    NAPSIMM_DEDO10_PITTS: {
      NAPSIMM_DEDO10_PITTS_R1: 0,
      NAPSIMM_DEDO10_PITTS_R2: 1,
      NAPSIMM_DEDO10_PITTS_R3: 2,
      NAPSIMM_DEDO10_PITTS_R4: 3,
    },
  },
  ONICODISTROFIA: {
    NAPSIMM_DEDO1_ONICODISTROFIA: {
      NAPSIMM_DEDO1_ONICODISTROFIA_R1: 0,
      NAPSIMM_DEDO1_ONICODISTROFIA_R2: 1,
      NAPSIMM_DEDO1_ONICODISTROFIA_R3: 2,
      NAPSIMM_DEDO1_ONICODISTROFIA_R4: 3,
    },
    NAPSIMM_DEDO2_ONICODISTROFIA: {
      NAPSIMM_DEDO2_ONICODISTROFIA_R1: 0,
      NAPSIMM_DEDO2_ONICODISTROFIA_R2: 1,
      NAPSIMM_DEDO2_ONICODISTROFIA_R3: 2,
      NAPSIMM_DEDO2_ONICODISTROFIA_R4: 3,
    },
    NAPSIMM_DEDO3_ONICODISTROFIA: {
      NAPSIMM_DEDO3_ONICODISTROFIA_R1: 0,
      NAPSIMM_DEDO3_ONICODISTROFIA_R2: 1,
      NAPSIMM_DEDO3_ONICODISTROFIA_R3: 2,
      NAPSIMM_DEDO3_ONICODISTROFIA_R4: 3,
    },
    NAPSIMM_DEDO4_ONICODISTROFIA: {
      NAPSIMM_DEDO4_ONICODISTROFIA_R1: 0,
      NAPSIMM_DEDO4_ONICODISTROFIA_R2: 1,
      NAPSIMM_DEDO4_ONICODISTROFIA_R3: 2,
      NAPSIMM_DEDO4_ONICODISTROFIA_R4: 3,
    },
    NAPSIMM_DEDO5_ONICODISTROFIA: {
      NAPSIMM_DEDO5_ONICODISTROFIA_R1: 0,
      NAPSIMM_DEDO5_ONICODISTROFIA_R2: 1,
      NAPSIMM_DEDO5_ONICODISTROFIA_R3: 2,
      NAPSIMM_DEDO5_ONICODISTROFIA_R4: 3,
    },
    NAPSIMM_DEDO6_ONICODISTROFIA: {
      NAPSIMM_DEDO6_ONICODISTROFIA_R1: 0,
      NAPSIMM_DEDO6_ONICODISTROFIA_R2: 1,
      NAPSIMM_DEDO6_ONICODISTROFIA_R3: 2,
      NAPSIMM_DEDO6_ONICODISTROFIA_R4: 3,
    },
    NAPSIMM_DEDO7_ONICODISTROFIA: {
      NAPSIMM_DEDO7_ONICODISTROFIA_R1: 0,
      NAPSIMM_DEDO7_ONICODISTROFIA_R2: 1,
      NAPSIMM_DEDO7_ONICODISTROFIA_R3: 2,
      NAPSIMM_DEDO7_ONICODISTROFIA_R4: 3,
    },
    NAPSIMM_DEDO8_ONICODISTROFIA: {
      NAPSIMM_DEDO8_ONICODISTROFIA_R1: 0,
      NAPSIMM_DEDO8_ONICODISTROFIA_R2: 1,
      NAPSIMM_DEDO8_ONICODISTROFIA_R3: 2,
      NAPSIMM_DEDO8_ONICODISTROFIA_R4: 3,
    },
    NAPSIMM_DEDO9_ONICODISTROFIA: {
      NAPSIMM_DEDO9_ONICODISTROFIA_R1: 0,
      NAPSIMM_DEDO9_ONICODISTROFIA_R2: 1,
      NAPSIMM_DEDO9_ONICODISTROFIA_R3: 2,
      NAPSIMM_DEDO9_ONICODISTROFIA_R4: 3,
    },
    NAPSIMM_DEDO10_ONICODISTROFIA: {
      NAPSIMM_DEDO10_ONICODISTROFIA_R1: 0,
      NAPSIMM_DEDO10_ONICODISTROFIA_R2: 1,
      NAPSIMM_DEDO10_ONICODISTROFIA_R3: 2,
      NAPSIMM_DEDO10_ONICODISTROFIA_R4: 3,
    },
  },
  LEUCONIQUIA: {
    NAPSIMM_DEDO1_LEUCONIQUIA: {
      NAPSIMM_DEDO1_LEUCONIQUIA_R1: 0,
      NAPSIMM_DEDO1_LEUCONIQUIA_R2: 1,
    },
    NAPSIMM_DEDO2_LEUCONIQUIA: {
      NAPSIMM_DEDO2_LEUCONIQUIA_R1: 0,
      NAPSIMM_DEDO2_LEUCONIQUIA_R2: 1,
    },
    NAPSIMM_DEDO3_LEUCONIQUIA: {
      NAPSIMM_DEDO3_LEUCONIQUIA_R1: 0,
      NAPSIMM_DEDO3_LEUCONIQUIA_R2: 1,
    },
    NAPSIMM_DEDO4_LEUCONIQUIA: {
      NAPSIMM_DEDO4_LEUCONIQUIA_R1: 0,
      NAPSIMM_DEDO4_LEUCONIQUIA_R2: 1,
    },
    NAPSIMM_DEDO5_LEUCONIQUIA: {
      NAPSIMM_DEDO5_LEUCONIQUIA_R1: 0,
      NAPSIMM_DEDO5_LEUCONIQUIA_R2: 1,
    },
    NAPSIMM_DEDO6_LEUCONIQUIA: {
      NAPSIMM_DEDO6_LEUCONIQUIA_R1: 0,
      NAPSIMM_DEDO6_LEUCONIQUIA_R2: 1,
    },
    NAPSIMM_DEDO7_LEUCONIQUIA: {
      NAPSIMM_DEDO7_LEUCONIQUIA_R1: 0,
      NAPSIMM_DEDO7_LEUCONIQUIA_R2: 1,
    },
    NAPSIMM_DEDO8_LEUCONIQUIA: {
      NAPSIMM_DEDO8_LEUCONIQUIA_R1: 0,
      NAPSIMM_DEDO8_LEUCONIQUIA_R2: 1,
    },
    NAPSIMM_DEDO9_LEUCONIQUIA: {
      NAPSIMM_DEDO9_LEUCONIQUIA_R1: 0,
      NAPSIMM_DEDO9_LEUCONIQUIA_R2: 1,
    },
    NAPSIMM_DEDO10_LEUCONIQUIA: {
      NAPSIMM_DEDO10_LEUCONIQUIA_R1: 0,
      NAPSIMM_DEDO10_LEUCONIQUIA_R2: 1,
    },
  },
  HEMORRAGIAS: {
    NAPSIMM_DEDO1_HEMORRAGIAS: {
      NAPSIMM_DEDO1_HEMORRAGIAS_R1: 0,
      NAPSIMM_DEDO1_HEMORRAGIAS_R2: 1,
    },
    NAPSIMM_DEDO2_HEMORRAGIAS: {
      NAPSIMM_DEDO2_HEMORRAGIAS_R1: 0,
      NAPSIMM_DEDO2_HEMORRAGIAS_R2: 1,
    },
    NAPSIMM_DEDO3_HEMORRAGIAS: {
      NAPSIMM_DEDO3_HEMORRAGIAS_R1: 0,
      NAPSIMM_DEDO3_HEMORRAGIAS_R2: 1,
    },
    NAPSIMM_DEDO4_HEMORRAGIAS: {
      NAPSIMM_DEDO4_HEMORRAGIAS_R1: 0,
      NAPSIMM_DEDO4_HEMORRAGIAS_R2: 1,
    },
    NAPSIMM_DEDO5_HEMORRAGIAS: {
      NAPSIMM_DEDO5_HEMORRAGIAS_R1: 0,
      NAPSIMM_DEDO5_HEMORRAGIAS_R2: 1,
    },
    NAPSIMM_DEDO6_HEMORRAGIAS: {
      NAPSIMM_DEDO6_HEMORRAGIAS_R1: 0,
      NAPSIMM_DEDO6_HEMORRAGIAS_R2: 1,
    },
    NAPSIMM_DEDO7_HEMORRAGIAS: {
      NAPSIMM_DEDO7_HEMORRAGIAS_R1: 0,
      NAPSIMM_DEDO7_HEMORRAGIAS_R2: 1,
    },
    NAPSIMM_DEDO8_HEMORRAGIAS: {
      NAPSIMM_DEDO8_HEMORRAGIAS_R1: 0,
      NAPSIMM_DEDO8_HEMORRAGIAS_R2: 1,
    },
    NAPSIMM_DEDO9_HEMORRAGIAS: {
      NAPSIMM_DEDO9_HEMORRAGIAS_R1: 0,
      NAPSIMM_DEDO9_HEMORRAGIAS_R2: 1,
    },
    NAPSIMM_DEDO10_HEMORRAGIAS: {
      NAPSIMM_DEDO10_HEMORRAGIAS_R1: 0,
      NAPSIMM_DEDO10_HEMORRAGIAS_R2: 1,
    },
  },
  HIPERQUERATOSIS: {
    NAPSIMM_DEDO1_HIPERQUERATOSIS: {
      NAPSIMM_DEDO1_HIPERQUERATOSIS_R1: 0,
      NAPSIMM_DEDO1_HIPERQUERATOSIS_R2: 1,
    },
    NAPSIMM_DEDO2_HIPERQUERATOSIS: {
      NAPSIMM_DEDO2_HIPERQUERATOSIS_R1: 0,
      NAPSIMM_DEDO2_HIPERQUERATOSIS_R2: 1,
    },
    NAPSIMM_DEDO3_HIPERQUERATOSIS: {
      NAPSIMM_DEDO3_HIPERQUERATOSIS_R1: 0,
      NAPSIMM_DEDO3_HIPERQUERATOSIS_R2: 1,
    },
    NAPSIMM_DEDO4_HIPERQUERATOSIS: {
      NAPSIMM_DEDO4_HIPERQUERATOSIS_R1: 0,
      NAPSIMM_DEDO4_HIPERQUERATOSIS_R2: 1,
    },
    NAPSIMM_DEDO5_HIPERQUERATOSIS: {
      NAPSIMM_DEDO5_HIPERQUERATOSIS_R1: 0,
      NAPSIMM_DEDO5_HIPERQUERATOSIS_R2: 1,
    },
    NAPSIMM_DEDO6_HIPERQUERATOSIS: {
      NAPSIMM_DEDO6_HIPERQUERATOSIS_R1: 0,
      NAPSIMM_DEDO6_HIPERQUERATOSIS_R2: 1,
    },
    NAPSIMM_DEDO7_HIPERQUERATOSIS: {
      NAPSIMM_DEDO7_HIPERQUERATOSIS_R1: 0,
      NAPSIMM_DEDO7_HIPERQUERATOSIS_R2: 1,
    },
    NAPSIMM_DEDO8_HIPERQUERATOSIS: {
      NAPSIMM_DEDO8_HIPERQUERATOSIS_R1: 0,
      NAPSIMM_DEDO8_HIPERQUERATOSIS_R2: 1,
    },
    NAPSIMM_DEDO9_HIPERQUERATOSIS: {
      NAPSIMM_DEDO9_HIPERQUERATOSIS_R1: 0,
      NAPSIMM_DEDO9_HIPERQUERATOSIS_R2: 1,
    },
    NAPSIMM_DEDO10_HIPERQUERATOSIS: {
      NAPSIMM_DEDO10_HIPERQUERATOSIS_R1: 0,
      NAPSIMM_DEDO10_HIPERQUERATOSIS_R2: 1,
    },
  },
  MANCHAS: {
    NAPSIMM_DEDO1_MANCHAS: {
      NAPSIMM_DEDO1_MANCHAS_R1: 0,
      NAPSIMM_DEDO1_MANCHAS_R2: 1,
    },
    NAPSIMM_DEDO2_MANCHAS: {
      NAPSIMM_DEDO2_MANCHAS_R1: 0,
      NAPSIMM_DEDO2_MANCHAS_R2: 1,
    },
    NAPSIMM_DEDO3_MANCHAS: {
      NAPSIMM_DEDO3_MANCHAS_R1: 0,
      NAPSIMM_DEDO3_MANCHAS_R2: 1,
    },
    NAPSIMM_DEDO4_MANCHAS: {
      NAPSIMM_DEDO4_MANCHAS_R1: 0,
      NAPSIMM_DEDO4_MANCHAS_R2: 1,
    },
    NAPSIMM_DEDO5_MANCHAS: {
      NAPSIMM_DEDO5_MANCHAS_R1: 0,
      NAPSIMM_DEDO5_MANCHAS_R2: 1,
    },
    NAPSIMM_DEDO6_MANCHAS: {
      NAPSIMM_DEDO6_MANCHAS_R1: 0,
      NAPSIMM_DEDO6_MANCHAS_R2: 1,
    },
    NAPSIMM_DEDO7_MANCHAS: {
      NAPSIMM_DEDO7_MANCHAS_R1: 0,
      NAPSIMM_DEDO7_MANCHAS_R2: 1,
    },
    NAPSIMM_DEDO8_MANCHAS: {
      NAPSIMM_DEDO8_MANCHAS_R1: 0,
      NAPSIMM_DEDO8_MANCHAS_R2: 1,
    },
    NAPSIMM_DEDO9_MANCHAS: {
      NAPSIMM_DEDO9_MANCHAS_R1: 0,
      NAPSIMM_DEDO9_MANCHAS_R2: 1,
    },
    NAPSIMM_DEDO10_MANCHAS: {
      NAPSIMM_DEDO10_MANCHAS_R1: 0,
      NAPSIMM_DEDO10_MANCHAS_R2: 1,
    },
  },
};

export const SCORAD = {
  ZONA: {
    ZONA_CABEZA_CUELLO: {
      SCORAD_ZONA_CABEZA_CUELLO_R1: 0,
      SCORAD_ZONA_CABEZA_CUELLO_R2: 0.5,
      SCORAD_ZONA_CABEZA_CUELLO_R3: 0.9,
      SCORAD_ZONA_CABEZA_CUELLO_R4: 1.4,
      SCORAD_ZONA_CABEZA_CUELLO_R5: 1.8,
    },
    ZONA_EXTREMIDADES_SUPERIORES_IZQUIERDA: {
      SCORAD_ZONA_EXTREMIDADES_SUPERIORES_IZQUIERDA_R1: 0,
      SCORAD_ZONA_EXTREMIDADES_SUPERIORES_IZQUIERDA_R2: 0.5,
      SCORAD_ZONA_EXTREMIDADES_SUPERIORES_IZQUIERDA_R3: 0.9,
      SCORAD_ZONA_EXTREMIDADES_SUPERIORES_IZQUIERDA_R4: 1.4,
      SCORAD_ZONA_EXTREMIDADES_SUPERIORES_IZQUIERDA_R5: 1.8,
    },
    ZONA_EXTREMIDADES_SUPERIORES_DERECHA: {
      SCORAD_ZONA_EXTREMIDADES_SUPERIORES_DERECHA_R1: 0,
      SCORAD_ZONA_EXTREMIDADES_SUPERIORES_DERECHA_R2: 0.5,
      SCORAD_ZONA_EXTREMIDADES_SUPERIORES_DERECHA_R3: 0.9,
      SCORAD_ZONA_EXTREMIDADES_SUPERIORES_DERECHA_R4: 1.4,
      SCORAD_ZONA_EXTREMIDADES_SUPERIORES_DERECHA_R5: 1.8,
    },
    ZONA_MIEMBROS_INFERIORES_IZQUIERDA: {
      SCORAD_ZONA_MIEMBROS_INFERIORES_IZQUIERDA_R1: 0,
      SCORAD_ZONA_MIEMBROS_INFERIORES_IZQUIERDA_R2: 0.9,
      SCORAD_ZONA_MIEMBROS_INFERIORES_IZQUIERDA_R3: 1.8,
      SCORAD_ZONA_MIEMBROS_INFERIORES_IZQUIERDA_R4: 2.7,
      SCORAD_ZONA_MIEMBROS_INFERIORES_IZQUIERDA_R5: 3.6,
    },
    ZONA_MIEMBROS_INFERIORES_DERECHA: {
      SCORAD_ZONA_MIEMBROS_INFERIORES_DERECHA_R1: 0,
      SCORAD_ZONA_MIEMBROS_INFERIORES_DERECHA_R2: 0.9,
      SCORAD_ZONA_MIEMBROS_INFERIORES_DERECHA_R3: 1.8,
      SCORAD_ZONA_MIEMBROS_INFERIORES_DERECHA_R4: 2.7,
      SCORAD_ZONA_MIEMBROS_INFERIORES_DERECHA_R5: 3.6,
    },
    ZONA_TRONCO_ANTERIOR: {
      SCORAD_ZONA_TRONCO_ANTERIOR_R1: 0,
      SCORAD_ZONA_TRONCO_ANTERIOR_R2: 0.9,
      SCORAD_ZONA_TRONCO_ANTERIOR_R3: 1.8,
      SCORAD_ZONA_TRONCO_ANTERIOR_R4: 2.7,
      SCORAD_ZONA_TRONCO_ANTERIOR_R5: 3.6,
    },
    ZONA_ESPALDA: {
      SCORAD_ZONA_ESPALDA_R1: 0,
      SCORAD_ZONA_ESPALDA_R2: 0.9,
      SCORAD_ZONA_ESPALDA_R3: 1.8,
      SCORAD_ZONA_ESPALDA_R4: 2.7,
      SCORAD_ZONA_ESPALDA_R5: 3.6,
    },
    ZONA_GENITALES: {
      SCORAD_ZONA_GENITALES_R1: 0,
      SCORAD_ZONA_GENITALES_R2: 0.1,
      SCORAD_ZONA_GENITALES_R3: "R2",
      SCORAD_ZONA_GENITALES_R4: "R2A",
      SCORAD_ZONA_GENITALES_R5: 0.2,
    },
  },
  SIGNOS: {
    SIGNOS_ROJEZ: {
      SCORAD_SIGNOS_ROJEZ_R1: 0,
      SCORAD_SIGNOS_ROJEZ_R2: 3.5,
      SCORAD_SIGNOS_ROJEZ_R3: 7,
      SCORAD_SIGNOS_ROJEZ_R4: 10.5,
    },
    SIGNOS_HINCHAZON: {
      SCORAD_SIGNOS_HINCHAZON_R1: 0,
      SCORAD_SIGNOS_HINCHAZON_R2: 3.5,
      SCORAD_SIGNOS_HINCHAZON_R3: 7,
      SCORAD_SIGNOS_HINCHAZON_R4: 10.5,
    },
    SIGNOS_COSTRAS: {
      SCORAD_SIGNOS_COSTRAS_R1: 0,
      SCORAD_SIGNOS_COSTRAS_R2: 3.5,
      SCORAD_SIGNOS_COSTRAS_R3: 7,
      SCORAD_SIGNOS_COSTRAS_R4: 10.5,
    },
    SIGNOS_MARCAS: {
      SCORAD_SIGNOS_MARCAS_R1: 0,
      SCORAD_SIGNOS_MARCAS_R2: 3.5,
      SCORAD_SIGNOS_MARCAS_R3: 7,
      SCORAD_SIGNOS_MARCAS_R4: 10.5,
    },
    SIGNOS_LIQUENIFICACION: {
      SCORAD_SIGNOS_LIQUENIFICACION_R1: 0,
      SCORAD_SIGNOS_LIQUENIFICACION_R2: 3.5,
      SCORAD_SIGNOS_LIQUENIFICACION_R3: 7,
      SCORAD_SIGNOS_LIQUENIFICACION_R4: 10.5,
    },
    SIGNOS_SEQUEDAD: {
      SCORAD_SIGNOS_SEQUEDAD_R1: 0,
      SCORAD_SIGNOS_SEQUEDAD_R2: 3.5,
      SCORAD_SIGNOS_SEQUEDAD_R3: 7,
      SCORAD_SIGNOS_SEQUEDAD_R4: 10.5,
    },
  },
  SINTOMAS: {
    SINTOMAS_PRURITO: {
      SCORAD_SINTOMAS_PRURITO_R1: 0,
      SCORAD_SINTOMAS_PRURITO_R2: 1,
      SCORAD_SINTOMAS_PRURITO_R3: 2,
      SCORAD_SINTOMAS_PRURITO_R4: 3,
      SCORAD_SINTOMAS_PRURITO_R5: 4,
      SCORAD_SINTOMAS_PRURITO_R6: 5,
      SCORAD_SINTOMAS_PRURITO_R7: 6,
      SCORAD_SINTOMAS_PRURITO_R8: 7,
      SCORAD_SINTOMAS_PRURITO_R9: 8,
      SCORAD_SINTOMAS_PRURITO_R10: 9,
      SCORAD_SINTOMAS_PRURITO_R11: 10,
    },
    SINTOMAS_INSOMNIO: {
      SCORAD_SINTOMAS_INSOMNIO_R1: 0,
      SCORAD_SINTOMAS_INSOMNIO_R2: 1,
      SCORAD_SINTOMAS_INSOMNIO_R3: 2,
      SCORAD_SINTOMAS_INSOMNIO_R4: 3,
      SCORAD_SINTOMAS_INSOMNIO_R5: 4,
      SCORAD_SINTOMAS_INSOMNIO_R6: 5,
      SCORAD_SINTOMAS_INSOMNIO_R7: 6,
      SCORAD_SINTOMAS_INSOMNIO_R8: 7,
      SCORAD_SINTOMAS_INSOMNIO_R9: 8,
      SCORAD_SINTOMAS_INSOMNIO_R10: 9,
      SCORAD_SINTOMAS_INSOMNIO_R11: 10,
    },
  },
};

export const ADCT = {
  ADCT_P1: {
    ADCT_P1_R1: 0,
    ADCT_P1_R2: 1,
    ADCT_P1_R3: 2,
    ADCT_P1_R4: 3,
    ADCT_P1_R5: 4,
  },
  ADCT_P2: {
    ADCT_P2_R1: 0,
    ADCT_P2_R2: 1,
    ADCT_P2_R3: 2,
    ADCT_P2_R4: 3,
    ADCT_P2_R5: 4,
  },
  ADCT_P3: {
    ADCT_P3_R1: 0,
    ADCT_P3_R2: 1,
    ADCT_P3_R3: 2,
    ADCT_P3_R4: 3,
    ADCT_P3_R5: 4,
  },
  ADCT_P4: {
    ADCT_P4_R1: 0,
    ADCT_P4_R2: 1,
    ADCT_P4_R3: 2,
    ADCT_P4_R4: 3,
    ADCT_P4_R5: 4,
  },
  ADCT_P5: {
    ADCT_P5_R1: 0,
    ADCT_P5_R2: 1,
    ADCT_P5_R3: 2,
    ADCT_P5_R4: 3,
    ADCT_P5_R5: 4,
  },
  ADCT_P6: {
    ADCT_P6_R1: 0,
    ADCT_P6_R2: 1,
    ADCT_P6_R3: 2,
    ADCT_P6_R4: 3,
    ADCT_P6_R5: 4,
  },
};


export const CDLQI = {
  CDLQI_P1: {
    CDLQI_P1_R1: 3,
    CDLQI_P1_R2: 2,
    CDLQI_P1_R3: 1,
    CDLQI_P1_R4: 0,
    CDLQI_P1_R5: null,
  },
  CDLQI_P2: {
    CDLQI_P2_R1: 3,
    CDLQI_P2_R2: 2,
    CDLQI_P2_R3: 1,
    CDLQI_P2_R4: 0,
    CDLQI_P2_R5: null,
  },
  CDLQI_P3: {
    CDLQI_P3_R1: 3,
    CDLQI_P3_R2: 2,
    CDLQI_P3_R3: 1,
    CDLQI_P3_R4: 0,
    CDLQI_P3_R5: null,
  },
  CDLQI_P4: {
    CDLQI_P4_R1: 3,
    CDLQI_P4_R2: 2,
    CDLQI_P4_R3: 1,
    CDLQI_P4_R4: 0,
    CDLQI_P4_R5: null,
  },
  CDLQI_P5: {
    CDLQI_P5_R1: 3,
    CDLQI_P5_R2: 2,
    CDLQI_P5_R3: 1,
    CDLQI_P5_R4: 0,
    CDLQI_P5_R5: null,
  },
  CDLQI_P6: {
    CDLQI_P6_R1: 3,
    CDLQI_P6_R2: 2,
    CDLQI_P6_R3: 1,
    CDLQI_P6_R4: 0,
    CDLQI_P6_R5: null,
  },
  CDLQI_P7: {
    CDLQI_P7_R1: 1,
    CDLQI_P7_R2: 0,
  },
  CDLQI_P7A: {
    CDLQI_P7A_R1: 3,
    CDLQI_P7A_R2: 2,
    CDLQI_P7A_R3: 1,
    CDLQI_P7A_R4: 0,
    CDLQI_P7A_R5: null,
  },
  CDLQI_P7B: {
    CDLQI_P7B_R1: 3,
    CDLQI_P7B_R2: 2,
    CDLQI_P7B_R3: 1,
    CDLQI_P7B_R4: 0,
    CDLQI_P7B_R5: null,
  },
  CDLQI_P8: {
    CDLQI_P8_R1: 3,
    CDLQI_P8_R2: 2,
    CDLQI_P8_R3: 1,
    CDLQI_P8_R4: 0,
    CDLQI_P8_R5: null,
  },
  CDLQI_P9: {
    CDLQI_P9_R1: 3,
    CDLQI_P9_R2: 2,
    CDLQI_P9_R3: 1,
    CDLQI_P9_R4: 0,
    CDLQI_P9_R5: null,
  },
  CDLQI_P10: {
    CDLQI_P10_R1: 3,
    CDLQI_P10_R2: 2,
    CDLQI_P10_R3: 1,
    CDLQI_P10_R4: 0,
    CDLQI_P10_R5: null,
  },
};


export const IDQOL = {
  IDQOL_P1: {
    IDQOL_P1_R1: 3,
    IDQOL_P1_R2: 2,
    IDQOL_P1_R3: 1,
    IDQOL_P1_R4: 0,
    IDQOL_P1_R5: null,
  },
  IDQOL_P2: {
    IDQOL_P2_R1: 3,
    IDQOL_P2_R2: 2,
    IDQOL_P2_R3: 1,
    IDQOL_P2_R4: 0,
    IDQOL_P2_R5: null,
  },
  IDQOL_P3: {
    IDQOL_P3_R1: 3,
    IDQOL_P3_R2: 2,
    IDQOL_P3_R3: 1,
    IDQOL_P3_R4: 0,
    IDQOL_P3_R5: null,
  },
  IDQOL_P4: {
    IDQOL_P4_R1: 3,
    IDQOL_P4_R2: 2,
    IDQOL_P4_R3: 1,
    IDQOL_P4_R4: 0,
    IDQOL_P4_R5: null,
  },
  IDQOL_P5: {
    IDQOL_P5_R1: 3,
    IDQOL_P5_R2: 2,
    IDQOL_P5_R3: 1,
    IDQOL_P5_R4: 0,
    IDQOL_P5_R5: null,
  },
  IDQOL_P6: {
    IDQOL_P6_R1: 3,
    IDQOL_P6_R2: 2,
    IDQOL_P6_R3: 1,
    IDQOL_P6_R4: 0,
    IDQOL_P6_R5: null,
  },
  IDQOL_P7: {
    IDQOL_P7_R1: 3,
    IDQOL_P7_R2: 2,
    IDQOL_P7_R3: 1,
    IDQOL_P7_R4: 0,
    IDQOL_P7_R5: null,
  },
  IDQOL_P8: {
    IDQOL_P8_R1: 3,
    IDQOL_P8_R2: 2,
    IDQOL_P8_R3: 1,
    IDQOL_P8_R4: 0,
    IDQOL_P8_R5: null,
  },
  IDQOL_P9: {
    IDQOL_P9_R1: 3,
    IDQOL_P9_R2: 2,
    IDQOL_P9_R3: 1,
    IDQOL_P9_R4: 0,
    IDQOL_P9_R5: null,
  },
  IDQOL_P10: {
    IDQOL_P10_R1: 3,
    IDQOL_P10_R2: 2,
    IDQOL_P10_R3: 1,
    IDQOL_P10_R4: 0,
    IDQOL_P10_R5: null,
  },
};